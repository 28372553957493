import { storageAccountService } from "@features/user/services";
import { StorageAccount } from ".";

export interface StorageAccountHandler {
    delete: () => Promise<void>
    initialize: () => Promise<StorageAccount>
    finalize: (code: string) => Promise<void>
}

export const googleDriveHandler:StorageAccountHandler = {
    delete: async () => {
        await storageAccountService.googledrive.delete();
    },
    initialize: async (): Promise<StorageAccount> => {
        const redirectUrl = await storageAccountService.googledrive.initializeAuthorizationFlow();
        window.location.href=redirectUrl;
        return Promise.reject();
    },
    finalize: async(code: string) => {
        await storageAccountService.googledrive.finalizeAuthorizationFlow(code);
    }
};

export const internalHandler: StorageAccountHandler = {
    delete: async () => {
        await storageAccountService.internal.delete();
    },
    initialize: async (): Promise<StorageAccount> => {
        return await storageAccountService.internal.create();
    },
    finalize: async(code: string) => {
        
    }
}