import CameraPhotoCapture from "@features/post/components/CameraPhotoCapture";
import { Dialog } from "@mui/material";

/**
 * 
 */
export const CaptureCameraDialog = (props: {open: boolean, onCancel: () => void, onValidate: (file: File) => void}) => {
    
    const onCancel = () => {
        props.onCancel();
    }

    const onValidate = (file: File) => {
        props.onValidate(file);
        props.onCancel();
    }

    return (
        <Dialog fullScreen open={props.open}>
            <CameraPhotoCapture
                onCancel={onCancel}
                onValidate={onValidate}/>
        </Dialog>
    );
}