import { useTranslation } from 'react-i18next';
import { useNavigate,  } from "react-router-dom";
import { SpeedDial, SpeedDialAction, Tooltip } from "@mui/material";
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import InstagramIcon from '@mui/icons-material/Instagram';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { useAppSelector } from '@app/hooks';
import { hasPrivilege } from '@helpers/security.helper';
import { UserPrivilege } from '@features/user/models';
import { userSelector } from '@features/user/slices';


interface PostCreationMenuProps {
    displayTooltip?: boolean,
    instaPath: string,
    uploadPath: string
}

export const PostCreationMenu = (props: PostCreationMenuProps) => {
    const { t } = useTranslation('menu_addposts');
    const { user } = useAppSelector(userSelector);
    const navigate = useNavigate();

    /**
     * 
     */
     const onUploadPostClick = () => {
        navigate(props.uploadPath);
    }

    /**
     * 
     */
    const onInstagramClick = () => {
        navigate(props.instaPath);
    }

    if(!hasPrivilege(user, UserPrivilege.POST_CREATION)) {
        return <></>;
    }

    /**
     * 
     */
    const postCreationMenu = [];
    if(hasPrivilege(user, UserPrivilege.POST_CREATION_UPLOAD)) {
        postCreationMenu.push(<SpeedDialAction icon={<FileUploadIcon />} tooltipTitle={t('menu_upload')} onClick={onUploadPostClick} key="creation_menu_upload" />);
    }
    if(hasPrivilege(user, UserPrivilege.POST_CREATION_INSTAGRAM)) {
        postCreationMenu.push(<SpeedDialAction icon={<InstagramIcon />} tooltipTitle={t('menu_instagram')} onClick={onInstagramClick} key="creation_menu_insta" />);
    }

    return (  
        <Tooltip open={props.displayTooltip} title={t('tooltip_createpost')} arrow placement="left-end">
            <SpeedDial
                ariaLabel=''
                sx={{ 
                    position: 'fixed',
                    bottom: 16,
                    right: 16 
                }}
                icon={<SpeedDialIcon />} >
                { postCreationMenu }
            </SpeedDial>
        </Tooltip>
    );
}