import { VideoMetadata } from '@features/post/models';

/**
 * Convert a dataurl to a file (image data url for example)
 * @param dataurl 
 * @param filename 
 * @returns 
 */
export function dataURLtoFile(dataurl: string, filename: string): File {
    var arr = dataurl.split(','), mime = arr[0]!.match(/:(.*?);/)![1],
    bstr = window.atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }


export async function generateVideoThumbnail(file: Blob, maxSize ?: number): Promise<string>{
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    // this is important
    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);

    video.onerror = (err) => {
      reject(err);
    }

    video.onloadeddata = () => {
      let ctx = canvas.getContext("2d");

      let width = video.videoWidth;
      let height = video.videoHeight;

      if(maxSize) {
        if (width > height) {
          if (width > maxSize) {
              height = height * (maxSize / width);
              width = maxSize;
          }
        } else {
          if (height > maxSize) {
              width = width * (maxSize / height);
              height = maxSize;
          }
        }
      }

      canvas.width = width;
      canvas.height = height;

      ctx!.drawImage(video, 0, 0, width, height);
      video.pause();
      return resolve(canvas.toDataURL("image/png"));
    };
  });
};

/**
 * 
 * @param file 
 */
export async function generatePictureThumbnail(file: Blob, maxSize : number): Promise<string> {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const img = document.createElement("img");

    // this is important
    img.src = URL.createObjectURL(file);

    img.onerror = (err) => {
      reject(err);
    }

    img.onload = () => {
      let ctx = canvas.getContext("2d");

      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxSize) {
            height = height * (maxSize / width);
            width = maxSize;
        }
      } else {
        if (height > maxSize) {
            width = width * (maxSize / height);
            height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;

      ctx!.drawImage(img, 0, 0, width, height);
      return resolve(canvas.toDataURL("image/png"));
    };
  });
};

/**
 * 
 * @param src 
 */
export async function getVideoMetadataFromSource(src: string): Promise<VideoMetadata> {
  const tempVideoEl = document.createElement('video')

  const durationP = new Promise<VideoMetadata>((resolve, reject) => {

    tempVideoEl.onerror = (err) => {
      reject(err);
    }

    tempVideoEl.addEventListener('loadedmetadata', () => {
      // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
      if(tempVideoEl.duration === Infinity) {
        tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER
        tempVideoEl.ontimeupdate = () => {
          tempVideoEl.ontimeupdate = null
          resolve({
            duration: Math.round(tempVideoEl.duration),
            width: tempVideoEl.videoWidth,
            height: tempVideoEl.videoHeight
          });
          tempVideoEl.currentTime = 0
        }
      }
      // Normal behavior
      else
      resolve({
        duration: Math.round(tempVideoEl.duration),
        width: tempVideoEl.videoWidth,
        height: tempVideoEl.videoHeight
      });
    })
    tempVideoEl.onerror = (event) => reject(event)
  })

  tempVideoEl.src = src;

  return durationP
};

/**
 * 
 * @param blob 
 * @returns 
 */
export async function getVideoMetadata(blob: Blob): Promise<VideoMetadata> {
  return getVideoMetadataFromSource(URL.createObjectURL(blob));
}

/**
 * 
 * @param metadata 
 */
export async function consolidateVideoMetadata(video: Blob, metadata: VideoMetadata) : Promise<VideoMetadata> {
  if(!metadata.duration || !metadata.width || !metadata.height) {
    const reloadedMetadata = await getVideoMetadata(video);
    return {
      duration: metadata.duration ? metadata.duration : reloadedMetadata.duration,
      width: metadata.width ? metadata.width : reloadedMetadata.width,
      height: metadata.height ? metadata.height : reloadedMetadata.height,
    }
  }
  return metadata;
}