export default class Paths {
    static Public = class {
        static root: string = '/';
        static signin: string = '/signin';
        static signup: string = '/signup';
        static emailConfirmation: string = '/signup/confirmation';
        static accountCreationSuccess: string = '/signup/success';
    }

    static Authenticated = class {
        static home: string = '/circle';
        static circle: string = '/circle/:circleId';
        static circleDetails: string = '/circle/:circleId/details';
        static circleSettings: string = '/circle/:circleId/settings';
        static circleViewAllPosts: string = '/circle/:circleId/posts';
        static circleViewPosts: string = '/circle/:circleId/posts/:postId?';
        static circleAddPost: string = '/circle/:circleId/posts/add';
        static circleCreatePost: string = '/circle/:circleId/posts/create';
        static circleSelectInstagramPost: string = '/circle/:circleId/posts/instagram/create';
        static circleContacts: string = '/circle/:circleId/contacts';
        static circleCreation: string = '/circle/create';
        
        static manageContacts: string = "/contacts/:tab?";
        static addContact: string = "/contacts/add";

        static externalAccounts: string = "/user/externalaccounts";
        static externalAccountsAuthorizationFinalization: string = "/user/externalaccounts/:storageType?";

        static account: string = "/user/account";
        static notifications: string = "/user/notifications";

        static instagram_posts: string = "/posts/instagram";

        static posts: string = "/posts";
        static postCreation: string = "/posts/create";
        static post: string = "/posts/:postId";
    }
}