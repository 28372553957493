import { useAppSelector } from "@app/hooks";
import { userSelector } from "@features/user/slices";
import { Avatar, SxProps, Theme, Tooltip } from "@mui/material"
import { useEffect, useState } from "react";

import userIcon from './assets/user-avatar/icon-256.png';
import { Circle } from "../models";

interface CircleAvatarProps {
    circle: {id: string, pictureUniqueId: string | null, name: string}
    sx ?: SxProps<Theme>
}

export const CircleAvatar = (props: CircleAvatarProps) => {

    return (<>
        {props.circle.pictureUniqueId && <Avatar src={`/api/circles/${props.circle.id}/picture?token=${props.circle.pictureUniqueId}`} sx={props.sx}/>}
        {!props.circle.pictureUniqueId && <Avatar sx={props.sx}>{props.circle.name.charAt(0).toUpperCase()}</Avatar>}
    </>);
};