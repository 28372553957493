import { Box, FormControl, InputLabel, Link, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useAppDispatch, useAppSelector } from "@app/hooks";
import { BusinessError } from "@app/errors";

import { Loading } from "@components/Loading";

import { commonSelector } from '@features/common/slices/commonSlice'

import { defaultThumbnailPostWidth, computeThumbnailPostSize } from '@features/post/helpers/post.helper';
import { InstagramThumbnailPost } from "@features/post/instagram/old/components/InstagramThumbnailPost";

import { InstagramAccount, InstagramPost } from "@features/post/instagram/old/models";
import { instagramService } from '@features/post/instagram/old/services';
import { postSlice } from "@features/post/slices";
import { Post } from "@features/post/models";
import { LazyScrollingContent } from "@components/LazyScrollingContent";

interface SelectPostProps {
    additionalSubmitSteps?: Array<{
        process: (post: Post) => void
    }>
}

export const InstagramPostSelection = (props: SelectPostProps) => {
    const { t } = useTranslation('select_instagram_posts');
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [ accounts, setAccounts ] = useState<Array<InstagramAccount> | null>(null);
    const [ selectedAccount, setSelectedAccount ] = useState<InstagramAccount | null>(null);
    const [ accountTokenExpired, setAccountTokenExpired ] = useState<boolean>(false);
    const [ authenticationUrl, setAuthenticationUrl ] = useState<string | null>(null);

    const [ posts, setPosts ] = useState<Array<InstagramPost> | null>(null);
    const [ hasMorePosts, setHasMorePosts ] = useState<boolean>(true);
    const [ nextPageCursor, setNextPageCursor ] = useState<string | null>(null);

    const { containerSize } = useAppSelector(commonSelector);
    const [postSize, setPostSize] = useState<number>(defaultThumbnailPostWidth);

    const [error, setError] = useState<string | null>(null);


    /**
     * If there is a code => use it to finalize the authentication
     * then load accounts & authentication url
     */
    useEffect(() => {
        (async () => {
            let code = searchParams.get('code');
            let newAccountAdded = false;

            if(code){
                if(code.substring(code.length - 2) === '#_') {
                    code = code.substring(0, code.length - 3);
                }

                // the loader is displayed while authenticationUrl & Accounts are null
                // so no need to handle some states
                try {
                    const addedAccount = await instagramService.finalizeAuthentication(code);
                    setSelectedAccount(addedAccount);
                    newAccountAdded = true;
                } catch(err) {
                    if(err instanceof BusinessError) {
                        setError(err.message);
                    }
                } finally {
                    setSearchParams({});
                }
            }

            setAuthenticationUrl(await instagramService.getAuthenticationUrl());
            const userAccounts = await instagramService.getAccounts();
            setAccounts(userAccounts);
            // auto select the only account if any
            if(!newAccountAdded && userAccounts.length === 1) {
                setSelectedAccount(userAccounts[0]);
            }
        })();
    }, []);

    /**
     * 
     */
    useEffect(() => {
        setPostSize(computeThumbnailPostSize(containerSize).postSize);
    }, [containerSize]);

    /**
     * 
     */
    useEffect(() => { 
        if(selectedAccount) {
            (async () => {
                setAccountTokenExpired(false);
                try {
                    loadPosts();
                } catch(err) {
                    if(err instanceof BusinessError) {
                        if(err.domain === 'storage_instagram' && err.code === 'expired_token') {
                            setError(err.message);
                            setAccountTokenExpired(true);
                        }
                    }
                }
            })();
        }
    }, [selectedAccount]);

    /**
     * 
     * @param event 
     */
    const onSelectAccount = (event: SelectChangeEvent) => {
        if(event.target.value === '') {
            setSelectedAccount(null);
        } else {
            const foundAccount = accounts?.find(a => a.id === event.target.value as string);
            setSelectedAccount(foundAccount ? foundAccount : null);
        }
    };

    /**
     * 
     * @param post 
     */
    const onPostClick = async (post: InstagramPost) => {
        // can only add post, to delete it => go to the post page because need to display the circle using it
        if(!post.added && posts && selectedAccount) {

            const newPost = await instagramService.createPost(selectedAccount.id, post.id);

            if (props.additionalSubmitSteps && props.additionalSubmitSteps.length > 0) {
                await props.additionalSubmitSteps.forEach(async step => {
                    await step.process(newPost);
                });
            }

            setPosts(
                posts.map(p => {
                    if(p.id === post.id) {
                        return {
                            ...p,
                            added: true
                        };
                    } else {
                        return p;
                    }
                })
            );

            // reset post state to reload all posts
            dispatch(postSlice.resetPostsState());
        }
    };

    /**
     * 
     */
    const loadPosts = async () => {
        if(selectedAccount != null) {
            const {posts: loadedPosts, paging} =  await instagramService.getPosts(selectedAccount.id, nextPageCursor);
            if(paging && paging.afterCursor) {
                setHasMorePosts(true);
                setNextPageCursor(paging.afterCursor);
            } else {
                setHasMorePosts(false);
                setNextPageCursor(null);
            }

            if(posts == null) {
                setPosts(loadedPosts);
            } else {
                setPosts(posts.concat(loadedPosts))
            }
        }
    };

    

    return (
        <>
            {(accounts == null || authenticationUrl == null) && <Loading />}

            {accounts != null 
                && accounts.length === 0
                && authenticationUrl
                && 
                <Box sx={{
                    display:'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    {error && <Typography color="error" sx={{mb: 1}}>{error}</Typography>}
                    <Link href={authenticationUrl} underline="none">{t('associate_account_link')}</Link>
                </Box>}

            {accounts != null 
                && accounts.length > 0
                && authenticationUrl
                && 
                <>
                    <Box sx={{
                        display:'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1
                    }}>
                        <FormControl fullWidth sx={{mr: 2}}>
                            <InputLabel id="select_account_label">{t('account_select_label')}</InputLabel>
                            <Select
                                value={selectedAccount ? selectedAccount.id : ''}
                                onChange={onSelectAccount}
                                labelId="select_account_label"
                                label={t('account_select_label')}>
                                {accounts.map(account => <MenuItem value={account.id} key={account.id}>{account.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <Link href={authenticationUrl}>
                            <AddCircleIcon fontSize="large"/>
                        </Link>
                    </Box>

                    {accountTokenExpired && 
                    <Box sx={{
                        display:'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%'
                    }}>
                        {error && <Typography color="error" sx={{mb: 1}}>{error}</Typography>}
                        <Link href={authenticationUrl} underline="none">{t('refresh_account_link')}</Link>
                    </Box>}

                    {selectedAccount == null && <Typography sx={{textAlign: 'center', mt: 3}}>{t('no_account_selected')}</Typography>}
                    {selectedAccount != null && posts == null && <Loading /> }
                    {selectedAccount != null && posts != null &&
                        <LazyScrollingContent height='100%' loadNext={loadPosts} items={posts} hasOlderItems={hasMorePosts} noElementText={t('no_post')}
                            render={(posts: Array<InstagramPost>) => 
                            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
                                {
                                    posts.map((post) => <InstagramThumbnailPost onClick={onPostClick} size={postSize} post={post} key={post.id} /> )
                                }
                            </div>
                        }/>
                    }
                </>}
        </>
    );
}