import axios from '@app/axios';
import { WebExtensionPost } from '../models';
import { StorageAccount } from '@features/user/models';

/**
 * 
 * @param description 
 */
const createPost = async (posts: Array<WebExtensionPost>, storageAccount: StorageAccount): Promise<Array<{id: string, postId: string}>> => {
    const { data } = await axios.post('/posts/instagram', {
        posts: posts.map(p => {
            return {id: p.id,
            description: p.description,
            timestamp: p.timestamp,
            medias: p.medias.map(m => {
                return {id: m.id,
                mediaType: m.type,
                url: m.url,
                width: m.width,
                height: m.height,
                metadata: m.metadata,
                thumbnail: m.thumbnail ? {
                    url: m.thumbnail.url,
                    width: m.thumbnail.width,
                    height: m.thumbnail.height
                }: null
            }})
        }}),
        storageType: storageAccount.type
    });
    return data;
};


const service = {
    createPost
};

export default service;