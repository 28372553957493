import unicorn from './assets/unicorn.png'
import slide1 from './assets/slide/slide-1.jpeg'
import slide2 from './assets/slide/slide-2.jpeg'
import slide3 from './assets/slide/slide-3.jpeg'
import slide4 from './assets/slide/slide-4.jpeg'
import slide5 from './assets/slide/slide-5.jpeg'
import slide6 from './assets/slide/slide-6.jpeg'
import slide7 from './assets/slide/slide-7.jpeg'
import slide8 from './assets/slide/slide-8.jpeg'
import slide9 from './assets/slide/slide-9.jpeg'

import './assets/PublicHome.css'
import useTranslate from '@hooks/useTranslate'

export const PublicHome = () => {
    const {t} = useTranslate('public_home');

    const pictures = [
        slide1,
        slide2,
        slide3,
        slide4,
        slide5,
        slide6,
        slide7,
        slide8,
        slide9
    ];

    const slidePortion = 1/5;
    const fullPercentPerPicture = Math.round((100 / pictures.length) * 10) / 10;
    const slidePercentPerPicture = Math.round((fullPercentPerPicture * slidePortion) * 10) / 10;
    const staticPercentPerPicture = Math.round((fullPercentPerPicture - slidePercentPerPicture) * 10) / 10;

    let buildKeyframes = '';
    for(let i = 0; i < pictures.length; i++) {
        buildKeyframes += ` ${i * fullPercentPerPicture}% {left: -${i * 100}%}`;
        buildKeyframes += ` ${i * fullPercentPerPicture + staticPercentPerPicture}% {left: -${i * 100}%}`;
    }
    buildKeyframes += ` 100% {left: -${pictures.length * 100}%}`;

    const slideDuration = pictures.length * 4;

    return (
        <div className="home-container">
            <div className="home-text">
                <h1>{t('title')}</h1>
                <p>{t('subtitle')}</p>
            </div>

            <div className="unicorn-container">
                <img src={unicorn} className="unicorn-img" />
                <div className="unicorn-slider">
                    <style>{`@keyframes slidy2{${buildKeyframes}}`}</style>
                    <div style={{width: `${pictures.length + 1}00%`, animation: `${slideDuration}s slidy2 infinite`}}>
                        {pictures.map(p => <img src={p} key={p}/>)}
                        <img src={pictures[0]} />
                    </div>
                </div>
            </div>
            
        </div>
    )
    
}