import axios from '@app/axios';
import { StorageAccount, StorageAccountType } from '@features/user/models';

/**
 * 
 * @returns 
 */
const loadStorageAccounts = async (): Promise<Array<StorageAccount>> => {
    const { data } = await axios.get(`/storage/account`);
    return data;
};

const googledrive = {
    initializeAuthorizationFlow: async(): Promise<string> => {
        const {data} = await axios.post(`/storage/account/googledrive/authorization/initialize`)
        return data;
    },
    finalizeAuthorizationFlow: async(code: string) => {
        await axios.post(`/storage/account/googledrive/authorization/finalize`, {code: code})
    },
    delete: async() => {
        await axios.delete(`/storage/account/googledrive`)
    }
};

const internal = {
    create: async(): Promise<StorageAccount> => {
        const {data} = await axios.post(`/storage/account/internal`);
        return data;
    },
    delete: async() => {
        await axios.delete(`/storage/account/internal`)
    }
};

const service = {
    loadStorageAccounts,
    googledrive,
    internal
};

export default service;