import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery, useTheme} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { dataURLtoFile } from '@helpers/file.helper';
import ReactCrop, { PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

interface CropPictureDialogProps {
    open: boolean,
    title: string,
    onCancel: () => void,
    onValidate: (file: File) => Promise<void>,
    picture: string
}

/**
 * 
 */
export const CropPictureDialog = (props: CropPictureDialogProps) => {
    const { t } = useTranslation('common');
    const theme= useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const imgRef = useRef<HTMLImageElement | null>(null);
    const [crop, setCrop] = useState<PixelCrop>();
    const [ cropMinWidth, setCropMinWidth ] = useState<number>(100);
    const finalImageSize: number = 500;

    const onPictureLoad = () => {
        if(imgRef.current) {
            const imgWidth = imgRef.current.width;
            const imgHeight = imgRef.current.height;

            const widthRatio = imgWidth / imgRef.current!.naturalWidth;

            setCropMinWidth(finalImageSize * widthRatio);
            
            let cropSize = imgWidth;
            if(imgHeight < imgWidth) {
                cropSize = imgHeight;
            }

            setCrop({
                x: 0,
                y: 0,
                width: cropSize,
                height: cropSize,
                unit: 'px'
            });
        }
    };

    const validate = async () => {
        if(crop) {
            const cropX = crop.x * imgRef.current!.naturalWidth / imgRef.current!.width;
            const cropY = crop.y * imgRef.current!.naturalHeight / imgRef.current!.height;

            const cropWidth = crop.width * imgRef.current!.naturalWidth / imgRef.current!.width;
            const cropHeight = crop.height * imgRef.current!.naturalHeight / imgRef.current!.height;

            const canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");

            canvas.width = finalImageSize;
            canvas.height = finalImageSize;

            ctx!.drawImage(
                imgRef.current!, 
                cropX,
                cropY,
                cropWidth, 
                cropHeight,
                0,
                0,
                finalImageSize, 
                finalImageSize
            );

            const pictureFile = dataURLtoFile(canvas.toDataURL("image/png"), `profile-${new Date().toISOString()}`);
            await props.onValidate(pictureFile);
        } else {
            props.onCancel();
        }
    };

    const cancel = () => {
        props.onCancel();
    }

    // 

    return (
        <Dialog
            open={props.open}
            onClose={cancel}
            fullScreen={fullScreen}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        "& .MuiDialogContent-root" : {
                            paddingBottom: 0
                        }
                    }
                },
            }}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box>
                    <ReactCrop crop={crop} onChange={c => setCrop(c)} circularCrop={true} aspect={1} minWidth={cropMinWidth}>
                        <img src={props.picture} ref={imgRef} style={{
                            maxHeight: 'calc(100vh - 187px)', 
                            maxWidth: '100%'
                        }} onLoad={onPictureLoad}/>
                    </ReactCrop>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={validate}>{t('validate_button')}</Button>
                <Button onClick={cancel}>{t('cancel_button')}</Button>
            </DialogActions>
        </Dialog>
    );
};