import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, Menu, MenuItem, Typography, Dialog, DialogTitle, DialogContent, ListItemButton, DialogActions } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

import { useAppDispatch, useAppSelector } from "@app/hooks";

import { LazyScrollingContent } from "@components/LazyScrollingContent";

import { CircleUser, CircleUserRole } from "@features/circle/models";

import { Contact } from "@features/contact/models";
import { contactSlice, contactSelector } from "@features/contact/slices";
import { UserAvatar } from "@components/icons/UserAvatar";








interface CircleUsersListProps {
    users: Array<CircleUser>
    addUsers: (users: Array<Contact>) => void,
    removeUser: (user: CircleUser) => void,
    updateUser: (user: CircleUser) => void,
    title ?: string
    maxHeight: string
}

interface ContactWithSelection extends Contact {
    inCircleUsersList: boolean
}

export const CircleUsersList = (props: CircleUsersListProps) => {
    const { t } = useTranslation('circle_creation');
    const { t: ct } = useTranslation('common');
    const { t: cut } = useTranslation('circle_user');
    const dispatch = useAppDispatch();

    const { title, users, addUsers, removeUser, updateUser, maxHeight} = props;

    // circle contact that triggers the menu
    const [selectedMenuCircleUser, setSelectedMenuCircleUser] = useState<CircleUser>();
    const [circleUserAnchorElMenu, setCircleUserAnchorElMenu] = useState<null | HTMLElement>(null);

    const [openContactDialog, setOpenContactDialog] = useState(false);
    // contact selected in the dialog
    const [selectedContacts, setSelectedContacts] = useState<Array<Contact>>([]);
    // all contacts of the user
    const { contacts, hasMoreContacts } = useAppSelector(contactSelector); 

    const [ dialogContacts, setDialogContacts] = useState<Array<ContactWithSelection> | null>(null);


    const addDialogContacts = (contacts: Array<Contact>) => {
        const updatedContacts = contacts.map(c => {
            const inCircleUsersList = users.findIndex(u => u.id === c.userId) >= 0;
            return {...c, inCircleUsersList};
        });

        if(dialogContacts) {
            setDialogContacts(dialogContacts.concat(updatedContacts));
        } else {
            setDialogContacts(updatedContacts);
        }
    }

    useEffect(() => {
        if(contacts != null) {
            addDialogContacts(contacts);
        }
    }, []);

    /**
     * 
     * @returns 
     */
     const loadContacts = async () => {
        const loadedContacts = await dispatch(contactSlice.loadContacts());
        addDialogContacts(loadedContacts);
    };

    /**
     * 
     * @param event 
     */
     const onOpenCircleContactMenu = (event: React.MouseEvent<HTMLElement>, circleUser: CircleUser) => {
        setCircleUserAnchorElMenu(event.currentTarget);
        setSelectedMenuCircleUser(circleUser);
    };

    /**
     * 
     */
    const onCloseCircleContactMenu = () => {
        setCircleUserAnchorElMenu(null);
        setSelectedMenuCircleUser(undefined);
    };

    /**
     * 
     * @param role 
     */
    const changeContactRole = (role: CircleUserRole) => {
        if(selectedMenuCircleUser) {
            updateUser({
                ...selectedMenuCircleUser,
                role
            });
        }
    };

    /**
     * 
     */
    const deleteCircleContact = async ()  => {
        if(selectedMenuCircleUser) {
            await removeUser(selectedMenuCircleUser);
        }
    }; 

    /**
     * Close & reset contact selection dialog
     */
    const closeContactSelectionDialog = () => {
        setSelectedContacts([]);
        setOpenContactDialog(false);
    };

    /**
     * Take all selected contacts in the dialog and add them to the final list of selected users as READER
     */
    const validateContactDialog = () => {
        addUsers(selectedContacts);
        setSelectedContacts([]);
        setOpenContactDialog(false);
    };

    /**
     * Add or remove the contact from the selected contact list 
     * @param event 
     * @param contact 
     */
    const toogleContactSelectionInDialog = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, contact: ContactWithSelection) => {
        // if the contact is not already in the final users list, toogle the selection
        if(!contact.inCircleUsersList) {
            const contactIndex = selectedContacts.findIndex(sc => sc.userId === contact.userId);
            if(contactIndex >= 0) {
                setSelectedContacts(selectedContacts.filter(sc => sc.userId !== contact.userId));
            } else {
                setSelectedContacts([contact, ...selectedContacts]);
            }
        }
    };

    return (
        <>
            <Box sx={{ p: 2}}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1}}>
                    {title && <Typography variant="h3">{title}</Typography>}
                    {!title && <div></div>}
                    <Button variant="outlined" color="primary" onClick={() => setOpenContactDialog(true)}>{t('button_add_contact')}</Button>
                </Box>

                {users.length === 0 &&
                    <Typography sx={{ textAlign: 'center', my: 2 }}>{t('no_contact_selected')}</Typography>
                }

                {users.length > 0 &&
                    <List sx={{ width: '100%', overflowY: 'auto', maxHeight: maxHeight }}>
                        {users.map(user =>
                            <ListItem key={user.id}
                                secondaryAction={user.role !== CircleUserRole.OWNER && <IconButton edge="end" onClick={e => onOpenCircleContactMenu(e, user)}>
                                        <MenuIcon />
                                    </IconButton>
                                }>
                                <ListItemAvatar>
                                    <UserAvatar user={user} />
                                </ListItemAvatar>
                                <ListItemText primary={user.username} secondary={cut(`role_${user.role}`)} />
                            </ListItem>
                        )}
                    </List>
                }
            </Box>

            <Menu
                sx={{ mt: '45px' }}
                anchorEl={circleUserAnchorElMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(circleUserAnchorElMenu)}
                onClose={onCloseCircleContactMenu}
                onClick={onCloseCircleContactMenu}
                transitionDuration={{ exit: 0 }}>
                {[
                    { role: CircleUserRole.ADMIN, label: 'contact_menu_to_admin', icon: <AdminPanelSettingsIcon /> },
                    { role: CircleUserRole.EDITOR, label: 'contact_menu_to_editor', icon: <EditIcon /> },
                    { role: CircleUserRole.READER, label: 'contact_menu_to_reader', icon: <VisibilityIcon /> },
                ].filter(item => selectedMenuCircleUser?.role !== item.role).map((item) => (
                    <MenuItem onClick={() => changeContactRole(item.role)} key={item.role}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        {t(item.label)}
                    </MenuItem>
                ))}

                <MenuItem onClick={deleteCircleContact}>
                    <ListItemIcon><DeleteIcon /></ListItemIcon>
                    {ct('delete_button')}
                </MenuItem>
            </Menu>

            <Dialog
                open={openContactDialog}
                onClose={closeContactSelectionDialog}
                scroll='paper'
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            minWidth: "70%",  // Set your width here
                        },
                    },
                }}>
                <DialogTitle>{t('contact_dialog_title')}</DialogTitle>
                <DialogContent dividers={true} sx={{height: '90vh'}}>
                    <LazyScrollingContent height="100%" loadNext={loadContacts} noElementText={t('dialog_no_contact')} items={dialogContacts} hasOlderItems={hasMoreContacts}
                        render={(contacts: Array<ContactWithSelection>) => 
                            <List sx={{ width: '100%'}}>
                                {contacts.map(contact => 
                                    <ListItemButton key={contact.userId} disabled={contact.inCircleUsersList} selected={selectedContacts.find(sc => sc.userId === contact.userId) !== undefined} 
                                            onClick={(event) => toogleContactSelectionInDialog(event, contact)}>
                                        <ListItemAvatar>
                                            <Avatar>C</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={contact.username}/>
                                    </ListItemButton>
                                )}
                            </List>
                        } />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeContactSelectionDialog}>{ct('cancel_button')}</Button>
                    <Button onClick={validateContactDialog}>{ct('validate_button')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};