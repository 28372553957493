import {useRef, useEffect, useState, forwardRef, ForwardedRef, SyntheticEvent, CSSProperties, useMemo} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper/modules";
import { Box } from '@mui/system';
import { debounce } from '@mui/material/utils';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import Replay5Icon from '@mui/icons-material/Replay5';
import Forward5Icon from '@mui/icons-material/Forward5';

import './PostViewer.css';

import { useAppSelector} from '@app/hooks';

import { commonSelector } from '@features/common/slices/commonSlice'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Post, PostMediaStatus, PostMediaSupplier, PostMediaType } from "@features/post/models"
import { DurationParseResult, parseDuration } from '@helpers/date.helper';
import { getVideoMetadataFromSource } from '@helpers/file.helper';
import { userSelector } from '@features/user/slices';
import { UserSettingsPostDisplay } from '@features/user/models';

interface PostViewerProps {
    post: Post
    focused ?: boolean
    onFocused ?: (scrollTopValue: number) => void
}

interface DisplayedMedia {
    id: string
    mediaType: PostMediaType
    width: number
    uri: string
    height: number
}

// pixels to remove if the picture is big enough to fit the entire screen to ensure the post data are visible
const postDataHeight = 60;

type PropsType = React.RefAttributes<HTMLElement> & PostViewerProps;

/**
 * 
 */
export const PostViewer: React.FunctionComponent<PropsType> = forwardRef((props: PostViewerProps, forwardedRef: ForwardedRef<HTMLElement>) => {
  const { containerSize } = useAppSelector(commonSelector);
  const postContainerRef = useRef<HTMLElement | null>(null)
  const { settings : userSettings} = useAppSelector(userSelector);

  const {post, focused, onFocused}  = props;
  const postMedias = post.medias;

  const [displayedMedias, setDisplayedMedias] = useState<Array<DisplayedMedia>>([]);
  const [targetSwiperHeight, setTargetSwiperHeight] = useState<number>(0);

  useEffect(() => {
    const containerHeight = containerSize.height - postDataHeight;
    const maxMediaWidth = containerSize.width;

    // first, for each active medias, build the URI of each medias including the container size
    // to find the best size
    const postMediasToDisplay = postMedias.filter(r => r.status === PostMediaStatus.ACTIVE)
    .map(r => {
      let uri = r.uri;
      if(r.supplier === PostMediaSupplier.INTERNAL) {
        uri = `${uri}?width=${maxMediaWidth}&height=${containerHeight}`
      }

      return {
        id: r.id,
        mediaType: r.type,
        width: 0,
        uri: uri,
        height: 0
      }
    });

    setDisplayedMedias(postMediasToDisplay);

    // the swiper height will be the max media height + margin
    setTargetSwiperHeight(postMediasToDisplay.map(s => s.height).sort((a, b) => b - a)[0]);

  }, [containerSize]);

  /**
   * 
   */
  const onMediaLoaded = () => {
    if(focused && onFocused) {
      const scrollTopValue = postContainerRef?.current?.offsetTop || 0;
      if(scrollTopValue > 0) {
        onFocused(scrollTopValue);
      }
    }
  };
  
  if(displayedMedias.length === 0) {
    return <></>;
  }

  let render;
  if(displayedMedias.length > 1) {
    if(userSettings?.postDisplay == UserSettingsPostDisplay.COLUMN) {
      render = <MultipleMediasColumn medias={displayedMedias} onMediaLoaded={onMediaLoaded} />
    } else {
      render = <SlideshowMedias medias={displayedMedias} onMediaLoaded={onMediaLoaded} />
    }
  } else if(displayedMedias.length === 1) {
    const media = displayedMedias[0];
    render = <SingleMedia media={media} onMediaLoaded={onMediaLoaded} />
  } else {
    render = <Box style={{}} sx={{
      position: 'relative',
      width:'100%', 
      height: targetSwiperHeight + 'px',
      background:'#000'}}>
      <InsertPhotoIcon sx={{width: '100%', height: '100%', color: '#BBB'}}/>
    </Box>
  }

  return (
    <Box
        sx= {{
            background: '#000'
        }}
        ref={(el: HTMLElement) => {
          postContainerRef.current = el;
          if(forwardedRef) {
            if (typeof forwardedRef === "function") {
              forwardedRef(el);
            } else if (typeof forwardedRef === "object") {
              forwardedRef.current = el;
            }
          }
        }}>
        {render}
    </Box>
  );
});


/**
 * 
 * @param props 
 * @returns 
 */
const SlideshowMedias = (props: {medias: Array<DisplayedMedia>, onMediaLoaded: () => void}) => {

  return (
    <Swiper
        style={{
          width:'100%', 
          maxHeight:'calc(100vh - 70px)',
        }}
        pagination={{
            dynamicBullets: true,
            clickable: true,
        }} 
        navigation={true}
        modules={[Pagination, Navigation]}>   
        {props.medias.map((media, index) => (
            <SwiperSlide key={media.id} style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
              {media.mediaType === PostMediaType.PICTURE &&
                <>
                  <PictureMedia media={media} onLoad={index === 0 ? props.onMediaLoaded : () => {}} lazyLoad={true} style={{maxWidth: 'calc(100% - 90px)' }}/>
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </>
              }
              {media.mediaType === PostMediaType.VIDEO && <VideoMedia media={media} onLoad={props.onMediaLoaded} style={{maxWidth: 'calc(100% - 90px)' }}/>}
            </SwiperSlide>
        ))}
    </Swiper>
  );
}


/**
 * 
 * @param props 
 * @returns 
 */
const SingleMedia = (props: {media: DisplayedMedia, onMediaLoaded: () => void}) => {

  return (
    <Box sx={{
      position: 'relative',
      width:'100%', 
      maxHeight:'calc(100vh - 70px)',
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background:'#000'}}>
        {props.media.mediaType === PostMediaType.PICTURE &&  <PictureMedia media={props.media} onLoad={props.onMediaLoaded} />}
        {props.media.mediaType === PostMediaType.VIDEO && <VideoMedia media={props.media} onLoad={props.onMediaLoaded} />}
    </Box>
  );
}

/**
 * 
 * @param props 
 * @returns 
 */
const MultipleMediasColumn = (props: {medias: Array<DisplayedMedia>, onMediaLoaded: () => void}) => {

  return (<>
    {props.medias.map((media, i) => 
      <Box sx={{mb: 2}}>
        <SingleMedia media={media} 
          onMediaLoaded={i == 0 ? props.onMediaLoaded : () => {}} />
      </Box>
    )}
  </>);
};

/**
 * 
 * @param props 
 * @returns 
 */
const PictureMedia = (props: {media: DisplayedMedia, lazyLoad ?: boolean, onLoad: (e:  SyntheticEvent<HTMLElement>) => void, className ?: string, style ?: CSSProperties}) => {
  const { media, style } = props;

  const defaultStyle: CSSProperties = {
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight:'calc(100vh - 70px)',
    ...style,
  };

  const onLoad = (e : SyntheticEvent<HTMLImageElement>) => {
    if(e.currentTarget.src.length > 0 && !e.currentTarget.src.startsWith('data')) {
      props.onLoad(e);
    }
  }

  return (
    <img
        loading={props.lazyLoad ? 'lazy' : 'eager'}
        src={media.uri}
        className={props.className}
        style={defaultStyle} 
        onLoad={onLoad} />
  )
};

/**
 * 
 * @param props 
 */
const VideoMedia = (props: {media: DisplayedMedia, onLoad: (e:  SyntheticEvent<HTMLElement>) => void, style ?: CSSProperties}) => {
  const { media, style } = props;
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [ hasBeenPlayed, setHasBeenPlayed ] = useState<boolean>(false);
  const [ paused, setPaused ] = useState<boolean>(true);
  const [ timer, setTimer ] = useState<string>('');
  const [ timerPercentage, setTimerPercentage ] = useState<number>(0);
  const [ displayControls, setDisplayControls ] = useState<boolean>(true);
  const [ fullVideoDuration, setFullVideoDuration ] = useState<DurationParseResult>();


  const defaultStyle: CSSProperties = {
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight:'calc(100vh - 70px)',
    ...style,
  };

  const togglePlayPause = () => {
    if(videoRef.current) {
      if(videoRef.current.paused) {
        videoRef.current.play();
        setDisplayControls(false);
        setHasBeenPlayed(true);
      } else {
        videoRef.current.pause();
        setDisplayControls(true);
      }
      setPaused(videoRef.current.paused);
    }
  };

  const stop = () => {
    if(videoRef.current) {
      videoRef.current.pause();
      setPaused(videoRef.current.paused);
      videoRef.current.currentTime = 0;
    }
  }

  const onEnded = () => {
    if(videoRef.current) {
      setPaused(videoRef.current.paused);
    }
  };

  const [nbTimeMove, setNbTimeMove] = useState<number>(0);
  const [ triggerTimeMove, setTriggerTimeMove ] = useState<boolean>(false);
  const timeMove = useMemo(() => debounce(
            async () => {
              setTriggerTimeMove(true);
            },
            300,
        ), []
  );

  useEffect(() => {
    if(triggerTimeMove) {
      setTriggerTimeMove(false);
      if(nbTimeMove !== 0) {
        if(videoRef.current) {
          const targetTime = videoRef.current.currentTime + nbTimeMove * 5;
          videoRef.current.currentTime = targetTime >= 0 ? targetTime : 0;
        }
        setNbTimeMove(0);
      }
    }
  }, [triggerTimeMove])

  const backward = () => {
      setNbTimeMove(nbTimeMove - 1);
      timeMove();
  }

  const forward = () => {
    setNbTimeMove(nbTimeMove + 1);
    timeMove();
  }

  const onTimeUpdate = (e: SyntheticEvent<HTMLVideoElement>) => {
    const mediaElmt = e.currentTarget;
    setTimer(durationParsingToString(parseDuration(mediaElmt.currentTime), fullVideoDuration && fullVideoDuration.hours > 0));
    setTimerPercentage(mediaElmt.currentTime * 100 / mediaElmt.duration);
  }

  const mouseEnter = () => {
    setDisplayControls(true);
  }

  const mouseLeave = () => {
    setDisplayControls(false);
  }

  const durationParsingToString = (duration: DurationParseResult, forceHours ?: boolean) => {
    if(duration.hours > 0 || forceHours) {
      return `${String(duration.hours).padStart(2, '0')}:${String(duration.minutes).padStart(2, '0')}:${String(duration.seconds).padStart(2, '0')}`;
    }
    return `${String(duration.minutes).padStart(2, '0')}:${String(duration.seconds).padStart(2, '0')}`;
  }

  const onLoad = (e: SyntheticEvent<HTMLVideoElement>) => {
    props.onLoad(e);
  }

  const onMetadataLoaded = async (e: SyntheticEvent<HTMLVideoElement>) => {
    let fullDuration = e.currentTarget.duration;
    if(!Number.isFinite(fullDuration)) {
      const { duration : loadedDuration } = await getVideoMetadataFromSource(media.uri);
      if(loadedDuration) {
        fullDuration = loadedDuration;
      }
    }

    if(fullDuration) {
      const duration = parseDuration(fullDuration);
      setFullVideoDuration(duration);
      if(duration.hours > 0) {
        setTimer('00:00:00');
      } else {
        setTimer('00:00');
      }
    }
  }

  return (<>
    <video 
      ref={videoRef}
      style={defaultStyle}
      onLoad={onLoad}
      onLoadedMetadata={onMetadataLoaded}
      onTimeUpdate={onTimeUpdate}
      onEnded={onEnded}
      preload='auto'>
      <source src={media.uri} />
      Your browser does not support the video tag.
    </video>

    <div style={{
      left:'45px',
      right:0,
      top: 0,
      position:'absolute',
      height: '100%',
      width: 'calc(100% - 90px)'
    }} className={paused ? "overlay paused" : 'overlay play'}>
        <div style={{
            width: '100%',
            height: 'calc(100% - 55px)' // the full height - control & slide bar
          }}
          onClick={togglePlayPause}></div>
    </div>

    <button style={{
        backgroundColor: 'transparent',
        border: 'none',
        color: '#fff',
        display: paused ? 'block' : 'none',
        width: 'fit-content',
        fontSize: '7rem',
        left: '0',
        margin: '0 auto',
        position: 'absolute',
        right: '0',
        top: 'calc(50% - 4rem)',
        lineHeight: 0
      }}
      onClick={togglePlayPause}>
      <PlayCircleOutlineIcon fontSize='inherit' sx={{opacity: 0.8}}/>
    </button>

    {hasBeenPlayed && 
      <div className={displayControls ? 'controls controls-active' : 'controls'} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          <button className="play" aria-label="play pause toggle" onClick={togglePlayPause}>
            {paused && <PlayArrowIcon />}
            {!paused && <PauseIcon />}
          </button>
          <button className="stop" aria-label="stop" onClick={stop}>
            <StopIcon />
          </button>
          <div className="timer">
            <div style={{width: timerPercentage + '%'}}></div>
            <span aria-label="timer">{timer}{fullVideoDuration ? ' / ' + durationParsingToString(fullVideoDuration) : ''}</span>
          </div>
          <button className="rwd" aria-label="rewind" onClick={backward}>
            <Replay5Icon />
          </button>
          <button className="fwd" aria-label="fast forward" onClick={forward}>
            <Forward5Icon />
          </button>
        </div>
      }
  </>);
}