import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Button, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { generatePath, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "@app/hooks";
import Paths from "@app/routes";

import { successChannel } from "@app/channels/globalNotificationChannel";

import { circleSlice, circleSelector } from "@features/circle/slices";

import { CirclePicture, CircleSettingsForm, IFormInputs as ICircleSettingsInputs } from '@features/circle/components/CircleSettingsForm';
import { CircleScope } from '@features/circle/models';

import { deepEqual } from "@helpers/objects.helper";
import { useConfirm } from "material-ui-confirm";
import { SelectGalleryPictureButton } from "@components/SelectGalleryPictureButton";


interface IFormInputs extends ICircleSettingsInputs {
}

export const UpdateCircleSettings = () => {
    const { t } = useTranslation('circle_update');
    const { t: ct } = useTranslation('common');
    const { circle } = useAppSelector(circleSelector);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const confirm = useConfirm();

    const [loading, setLoading] = useState<boolean>(false);

    const [circlePicture, setCirclePicture] = useState<{file: File | null, source: string} | null>(null);

    const updateCirclePicture = (circlePicture: CirclePicture | null) => {
        setCirclePicture(circlePicture);
    }

    useEffect(() => {
        if(circle && circle.pictureUniqueId) {
            setCirclePicture({
                file: null,
                source: `/api/circles/${circle.id}/picture?token=${circle.pictureUniqueId}`
            })
        } else {
            setCirclePicture(null);   
        }
    }, [circle?.pictureUniqueId]);

    /**
     * 
     */
    const validationSchema = Yup.object({
        name: Yup.string().required(ct('input_required')),
        hideUsers: Yup.boolean().defined(),
        allowComments: Yup.boolean().defined(),
        allowReactions: Yup.boolean().defined(),
        scope: Yup.mixed<CircleScope>().oneOf(Object.values(CircleScope)).defined()
    });

    /**
     * 
     */
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            hideUsers: circle!.settings.hideUsers,
            allowComments: circle!.settings.allowComments,
            allowReactions: circle!.settings.allowReactions,
            name: circle!.name,
            scope: circle!.settings.scope
        }
    });

    /**
     * 
     * @param data 
     */
    const onSubmit = async (data: IFormInputs) => {
        if(circle) {
            setLoading(true);

            if(circle.name !== data.name) {
                await dispatch(circleSlice.updateCircle(circle.id, data.name));
            }

            const updatedSettings = {
                hideUsers: data.hideUsers,
                allowComments: data.allowComments,
                allowReactions: data.allowReactions,
                scope: data.scope
            };

            if(!deepEqual(circle.settings, updatedSettings)) {
                await dispatch(circleSlice.updateCircleSettings(circle.id, updatedSettings));
            }

            // there is a file -> the picture has been updated
            if(circlePicture && circlePicture.file) {
                await dispatch(circleSlice.updateCirclePicture(circle.id, circlePicture.file));
            }
            // there was a picture and the new one is empty -> delete it
            else if(circle.pictureUniqueId && circlePicture == null) {
                await dispatch(circleSlice.deleteCirclePicture(circle.id));
            }

            successChannel.emit(t('notification_update_success'));
            navigate(generatePath(Paths.Authenticated.circle, {"circleId": circle.id}));
        }
    }

    const deleteCircle = async() => {
        if(circle) {
            try {
                await confirm({description: t('confirm_delete_circle')});
                await dispatch(circleSlice.deleteCircle(circle.id));
                successChannel.emit(t('notification_circle_delete_success'));
                navigate(Paths.Authenticated.home);
            } catch(err){}
        }
    }

    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <CircleSettingsForm control={control} errors={errors} circlePicture={circlePicture} setCirclePicture={updateCirclePicture} />

            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', mt: 2 }}>
                <LoadingButton loading={loading} variant="outlined" color="primary" type="submit" >
                    {ct('validate_button')}
                </LoadingButton>
                <Button variant="outlined" color="error" onClick={deleteCircle} sx={{ml: 2}}>
                    {t('delete_circle_button')}
                </Button>
            </Box>
        </Box>
    )
}