import { Avatar, Box, FormControl, FormControlLabel, FormHelperText, IconButton, MenuItem, Select, Switch, TextField } from "@mui/material";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';

import { CircleScope } from "@features/circle/models";
import { CropPictureDialog } from "@components/CropPictureDialog";
import { CaptureCameraDialog } from "@components/CaptureCameraDialog";
import { SelectGalleryPictureButton } from "@components/SelectGalleryPictureButton";
import { useState } from "react";
import useCameraDevice from "@hooks/useCameraDevice";
import { useConfirm } from "material-ui-confirm";

/**
 * 
 */
 export interface IFormInputs {
    hideUsers: boolean
    allowComments: boolean
    allowReactions: boolean
    name: string
    scope: CircleScope
}

export interface CirclePicture {
    file: File | null,
    source: string
}

/**
 * 
 */
interface CircleSettingsFormProps {
    control: Control<IFormInputs>,
    errors: FieldErrors<IFormInputs>,
    circlePicture: CirclePicture | null,
    setCirclePicture: (circlePicture: CirclePicture | null) => void
}

/**
 * 
 * @param props 
 * @returns 
 */
export const CircleSettingsForm = (props: CircleSettingsFormProps) => {
    const { t } = useTranslation('circle_settings');
    const { t: cct } = useTranslation('circle');
    const { control , errors} = props;
    const hasCameraDevice = useCameraDevice();
    const confirm = useConfirm();
    const { circlePicture, setCirclePicture } = props;

    const [ tmpCirclePicture, setTmpCirclePicture] = useState<string | null>(null);
    const [ showCaptureCameraDialog, setShowCaptureCameraDialog ] = useState(false);
    const [showCropCirclePictureDialog, setShowCropCirclePictureDialog] = useState(false);

    const openCaptureCameraDialog = () => setShowCaptureCameraDialog(true);
    const closeCaptureCameraDialog = () => setShowCaptureCameraDialog(false);

    const openCropCirclePictureDialog = () => setShowCropCirclePictureDialog(true);
    const closeCropCirclePictureDialog = () => {
        setTmpCirclePicture(null);
        setCirclePicture(null);
        setShowCropCirclePictureDialog(false);
    }
    
    /**
     * 
     * @param event 
     */
    const handleMediaGallerySelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = Array.from(event.target.files)[0];
            if(file.type.includes("image/")) {
                handlePictureCapture(file);
            }
        }
    };

    const handlePictureCapture = (file: File) => {
        setTmpCirclePicture(URL.createObjectURL(file));
        openCropCirclePictureDialog();
    }

    const onCropValidate = async(pictureFile: File) => {
        setShowCropCirclePictureDialog(false);
        setTmpCirclePicture(null);
        setCirclePicture({
            source: URL.createObjectURL(pictureFile),
            file: pictureFile
        });
    }

    const deleteCirclePicture = async() => {
        try {
            await confirm({description: t('confirm_delete_picture')});
            setTmpCirclePicture(null);
            setCirclePicture(null);
        }catch(err){}
    };

    return (
        <Box sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', m: 2}}>
            <Box sx={{position: 'relative', flexBasis: '200px'}}>
                {circlePicture && <Avatar sx={{width: '200px', height: '200px'}} src={circlePicture.source}/>}
                {!circlePicture && <Avatar sx={{width: '200px', height: '200px'}}>{t('avatar_choose_picture')}</Avatar>}

                <Box sx={{position:'absolute', bottom: 0, right: 0, display:'flex'}}>
                    <Avatar sx={{bgcolor: 'primary.main'}}>
                        <SelectGalleryPictureButton handleCapture={handleMediaGallerySelection} sx={{color: 'white'}}/>
                    </Avatar>
                    {hasCameraDevice && 
                        <Avatar sx={{bgcolor: 'primary.main', ml: 1}} onClick={openCaptureCameraDialog}>
                            <PhotoCameraIcon />
                        </Avatar>
                    }
                    {circlePicture && 
                        <IconButton onClick={deleteCirclePicture}><DeleteIcon sx={{color: 'error.main'}}/></IconButton>
                    }
                </Box>
            </Box>
            <Box sx={{flexGrow: 2, ml: 2, pr: 2}}>
                <FormControl sx={{ width: '100%', my: 2 }}>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => <TextField {...field}
                            label={t('input_name_label')} />
                        } />
                    <FormHelperText sx={{color: "error.main"}}>{errors.name?.message}</FormHelperText>

                    <FormControlLabel
                        control={<Controller
                            name="hideUsers"
                            control={control}
                            render={({ field }) => <Switch
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value || false} />
                            } />}
                        label={t('switch_hideUsers')}
                        labelPlacement="start"
                        sx={{
                            mt: 2,
                            "& .MuiFormControlLabel-label": {
                                flexGrow: 1
                            }
                        }} />

                    <FormControlLabel
                        control={<Controller
                            name="allowComments"
                            control={control}
                            render={({ field }) => <Switch
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value || false} />
                            } />}
                        label={t('switch_allowComments')}
                        labelPlacement="start"
                        sx={{
                            "& .MuiFormControlLabel-label": {
                                flexGrow: 1
                            }
                        }} />

                    <FormControlLabel
                        control={<Controller
                            name="allowReactions"
                            control={control}
                            render={({ field }) => <Switch
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value || false} />
                            } />}
                        label={t('switch_allowReactions')}
                        labelPlacement="start"
                        sx={{
                            "& .MuiFormControlLabel-label": {
                                flexGrow: 1
                            },
                            mb: 2
                        }} />

                    <FormControlLabel
                        control={<Controller
                            name="scope"
                            control={control}
                            render={({ field }) => (
                                <Select {...field}>
                                    <MenuItem value={CircleScope.PUBLIC}>{cct('scope_public')}</MenuItem>
                                    <MenuItem value={CircleScope.PRIVATE}>{cct('scope_private')}</MenuItem>
                                </Select>
                            )}
                        />}
                        label={t('select_scope')}
                        labelPlacement="start"
                        sx={{
                            "& .MuiFormControlLabel-label": {
                                flexGrow: 1
                            }
                        }} />
                    </FormControl>
            </Box>

            <CropPictureDialog open={showCropCirclePictureDialog} onCancel={closeCropCirclePictureDialog} picture={tmpCirclePicture!} title={t('dialog_update_cicle_picture_title')} onValidate={onCropValidate}/>
            <CaptureCameraDialog open={showCaptureCameraDialog} onCancel={closeCaptureCameraDialog} onValidate={handlePictureCapture}/>
        </Box>
    );
};