import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const GoogleDriveIcon = (props: SvgIconProps) => {

    return (
        <SvgIcon {...props} viewBox='0 0 250 250'>
            <linearGradient id="a" x1="0%" x2="100%" y1="0%" y2="100%"><stop offset="0" stopColor="#f6c338"/><stop offset=".522782398" stopColor="#ffd351"/><stop offset="1" stopColor="#f6c338"/></linearGradient><linearGradient id="b" x1="100%" x2="0%" y1="0%" y2="100%"><stop offset="0" stopColor="#286ee6"/><stop offset=".521046125" stopColor="#4286fb"/><stop offset="1" stopColor="#286ee6"/></linearGradient><linearGradient id="c" x1="65.289307%" x2="35.995483%" y1="0%" y2="100%"><stop offset="0" stopColor="#069b5a"/><stop offset=".531031222" stopColor="#11aa62"/><stop offset="1" stopColor="#069b5a"/></linearGradient><path d="m83.3 0h89l83.7 144.3h-89.2z" fill="url(#a)"/><path d="m256 144.3-44.6 77.1h-167l44.7-77.1z" fill="url(#b)"/><path d="m44.4 221.4-44.4-77.1 83.3-144.3 44.7 77.3z" fill="url(#c)"/><path d="m44.4 221.4 83.1-77.1h-38.4z" opacity=".1"/><path d="m256 144.3h-89.1l-19.6-33.8z" opacity=".1"/><path d="m83.3 0 25.7 110 19-32.7z" opacity=".1"/>
        </SvgIcon>
    );
};

export default GoogleDriveIcon;

