import { Routes, Route} from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";
import { useTranslation } from "react-i18next";
import { ThemeProvider, CssBaseline } from "@mui/material";

import "./App.css";

import { appTheme } from "@app/themes/theme";
import Paths from '@app/routes';

import ProtectedRoutes from "@components/security/ProtectedRoutes";
import PublicRoutes from "@components/security/PublicRoutes";

import PublicLayout from '@layouts/PublicLayout';
import AuthenticatedLayout from '@layouts/AuthenticatedLayout';
import { CommonLayout } from "@layouts/CommonLayout";

import {SignIn} from "@features/auth/pages/SignIn";
import {SignUp} from "@features/auth/pages/Signup";
import AccountCreationSuccess from "@features/auth/pages/AccountCreationSuccess";
import EmailConfirmation from "@features/auth/pages/EmailConfirmation";

import { ViewContactsAndInvitations } from "@features/contact/pages/ViewContactsAndInvitations";
import { AddContact } from "@features/contact/pages/AddContact";

import { PublicHome } from '@features/home'

import { ViewPosts } from "@features/post/pages/ViewPosts";
import CreatePost from "@features/post/pages/CreatePost";
import ViewPost from "@features/post/pages/ViewPost";

import { ViewInstagramPosts } from "@features/post/instagram/pages/ViewInstagramPosts";

import { UserAccount } from '@features/user/pages/Account'
import { UserExternalAccounts } from "@features/user/pages/UserExternalAccounts";
import { Notifications } from '@features/user/pages/Notifications'


import { CircleLayout } from "@features/circle/components/CircleLayout";
import { ViewCirclePosts } from "@features/circle/pages/ViewCirclePosts";
import { UpdateCircleSettings } from "@features/circle/pages/UpdateCircleSettings";
import { AddCirclePost } from "@features/circle/pages/AddCirclePost";
import { CreateCirclePost } from "@features/circle/pages/CreateCirclePost";
import { SelectCircleInstagramPost } from "@features/circle/pages/SelectCircleInstagramPost";
import { ManageCircleContacts } from "@features/circle/pages/ManageCircleContacts";
import { CreateCircle } from "@features/circle/pages/CreateCircle";
import { ViewCircleDetails } from "@features/circle/pages/ViewCircleDetails";
import { ViewAllCirclePosts } from "@features/circle/pages/ViewAllCirclePosts";


const App = () => {
  const { t } = useTranslation('common');

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline enableColorScheme />
      <ConfirmProvider defaultOptions={{
        confirmationText: t('validate_button'),
        cancellationText: t('cancel_button'),
        title: ''
      }}>
        <Routes>
          <Route element={<CommonLayout/>}>
            <Route element={<ProtectedRoutes/>}>
              <Route element={<AuthenticatedLayout />}>
                <Route path={Paths.Authenticated.account} element={<UserAccount />} />
                <Route path={Paths.Authenticated.notifications} element={<Notifications />} />
                <Route path={Paths.Authenticated.externalAccounts} element={<UserExternalAccounts />} />
                <Route path={Paths.Authenticated.externalAccountsAuthorizationFinalization} element={<UserExternalAccounts />} />
                <Route element={<CircleLayout />}>
                  <Route path={Paths.Authenticated.home} element={<ViewCirclePosts />} />
                  <Route path={Paths.Authenticated.circle} element={<ViewCirclePosts />} />
                  <Route path={Paths.Authenticated.circleDetails} element={<ViewCircleDetails />} />
                  <Route path={Paths.Authenticated.circleSettings} element={<UpdateCircleSettings />} />
                  <Route path={Paths.Authenticated.circleViewPosts} element={<ViewAllCirclePosts />} />
                  <Route path={Paths.Authenticated.circleAddPost} element={<AddCirclePost />} />
                  <Route path={Paths.Authenticated.circleCreatePost} element={<CreateCirclePost />} />
                  <Route path={Paths.Authenticated.circleSelectInstagramPost} element={<SelectCircleInstagramPost />} />
                  <Route path={Paths.Authenticated.circleContacts} element={<ManageCircleContacts />} />
                </Route>
                <Route path={Paths.Authenticated.instagram_posts} element={<ViewInstagramPosts />} />
                <Route path={Paths.Authenticated.posts} element={<ViewPosts />} />
                <Route path={Paths.Authenticated.post} element={<ViewPost />} />
                <Route path={Paths.Authenticated.postCreation} element={<CreatePost />} />
                <Route path={Paths.Authenticated.circleCreation} element={<CreateCircle />} />
                <Route path={Paths.Authenticated.manageContacts} element={<ViewContactsAndInvitations />} />
                <Route path={Paths.Authenticated.addContact} element={<AddContact />} />
              </Route>
            </Route>

            <Route element={<PublicRoutes/>}>
            <Route element={<PublicLayout />}>
                <Route path={Paths.Public.signin} element={<SignIn />} />
                <Route path={Paths.Public.signup} element={<SignUp />} />
                <Route path={Paths.Public.emailConfirmation} element={<EmailConfirmation />} />
                <Route path={Paths.Public.accountCreationSuccess} element={<AccountCreationSuccess />} />
                <Route path={Paths.Public.root} element={<PublicHome />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </ConfirmProvider>
    </ThemeProvider>
  );
};

export default App;