
export interface Post {
    id: string
    description ?: string | null
    user: {id: string, username: string, pictureUniqueId: string | null}
    type: PostType
    status: PostStatus
    medias: Array<PostMedia>
}

export enum PostStatus {
    ACTIVE = "ACTIVE",
    PROCESSING = "PROCESSING"
}

export interface SelectablePost extends Post {
    selected: boolean
}

export enum PostMediaType {
    PICTURE = "PICTURE",
    VIDEO = "VIDEO",
}

export enum PostType {
    INSTAGRAM = "INSTAGRAM",
    INTERNAL = "INTERNAL"
}

export enum PostMediaSizeType {
    THUMBNAIL = "THUMBNAIL",
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
    ORIGINAL = "ORIGINAL"
}

export enum PostMediaStatus {
    ACTIVE = "ACTIVE",
    PENDING = "PENDING"
}

export enum PostMediaSupplier {
    INTERNAL = "INTERNAL",
    INSTAGRAM = "INSTAGRAM"
}

export enum PostExternalIdentifierType {
    INSTAGRAM = "INSTAGRAM"
}

export interface PostMediaSize {
    type: PostMediaSizeType,
    width: number,
    height: number
}

export interface PostMedia {
    id: string
    mediaId: string
    supplier: PostMediaSupplier
    type: PostMediaType
    status: PostMediaStatus
    uri: string
}

export interface PostCircleOverview {
    id: string,
    name: string,
    nbUsers: number,
    creationDate: string
}

export interface VideoMetadata {
    duration ?: number | null,
    width ?: number | null,
    height ?: number | null
}