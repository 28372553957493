import { Post, PostMediaStatus, PostMediaType, PostStatus } from "@features/post/models";
import { GenericPostType, GenericThumbnailPost } from '@features/post/components/GenericThumbnailPost';

interface ThumbnailPostProps {
    post: Post
    size: number
    checked ?: boolean
    selectable ?: boolean
    onClick ?: (post: Post) => void
    onLongPress ?: (post: Post) => void
}

export const ThumbnailPost = (props: ThumbnailPostProps) => {
    const post = props.post;
    
    const onClick = () => {
        if(props.onClick) {
            props.onClick(post);
        }
    }

    const onLongPress = () => {
        if(props.onLongPress) {
            props.onLongPress(post);
        }
    }

    const genericPost = {
        id: post.id,
        description: post.description,
        type: post.medias.length > 1 ? GenericPostType.ALBUM : (post.medias.length === 1 && post.medias[0].type === PostMediaType.VIDEO ? GenericPostType.VIDEO : GenericPostType.DEFAULT),
        thumbnailUri: post.status === PostStatus.ACTIVE ? `/api/posts/${post.id}/picture` : null
    };

    return <GenericThumbnailPost onLongPress={onLongPress} onClick={onClick} size={props.size} post={genericPost} checked={props.checked} selectable={props.selectable}/>
}