import axios from '@app/axios';
import { Notification } from '@features/user/models';


interface LoadNotificationData {
    hasNext: boolean
    notifications: Array<Notification>
}

/**
 * 
 * @returns 
 */
const loadNotifications = async (page: number = 0): Promise<LoadNotificationData> => {
    const { data } = await axios.get<LoadNotificationData>(`/notifications?page=${page}`);
    return data;
};

/**
 * 
 */
const getUnreadNotifications = async (): Promise<number> => {
    const { data } = await axios.get('/notifications/unread');
    return data;
};

/**
 * 
 * @returns 
 */
const markAllRead = async () => {
    await axios.post('/notifications/read');
};

/**
 * 
 */
const acceptNotification = async (id: string): Promise<Notification> => {
    const { data } = await axios.post(`/notifications/${id}/accept`);
    return data;
};

/**
 * 
 */
const refuseNotification = async (id: string) : Promise<Notification> => {
    const { data } = await axios.post(`/notifications/${id}/refuse`);
    return data;
};

const service = {
    loadNotifications,
    getUnreadNotifications,
    markAllRead,
    acceptNotification,
    refuseNotification
};

export default service;