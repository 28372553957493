import { useAppSelector } from '@app/hooks';
import { storageAccountService } from '@features/user/services';

import { useState, useEffect } from 'react';
import { userSelector } from '../slices';
import { StorageAccount, StorageAccountStatus } from '../models';

export default function useStorageAccounts() {
    const [ storageAccounts, setStorageAccounts ] = useState<Array<StorageAccount> | null>(null);
    const [ preferredStorage, setPreferredStorage ] = useState<StorageAccount | null>(null);
    const { settings } = useAppSelector(userSelector);

  useEffect(() => {
    (async () => {
        const accounts = await storageAccountService.loadStorageAccounts();
        setStorageAccounts(accounts.filter(a => a.status === StorageAccountStatus.ACTIVE));
        if(accounts.length > 0) {
            setPreferredStorage(settings!.preferredStorageType == null ? 
                accounts[0] : 
                accounts.filter(a => a.type === settings!.preferredStorageType)[0]);
        }
    })();
}, []);

  return {
    storageAccounts,
    preferredStorage
  };
}