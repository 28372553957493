import axios from '@app/axios';
import { UserSettings } from '../models';

/**
 * 
 * @returns 
 */
const getUserSettings = async (): Promise<UserSettings> => {
    const { data } = await axios.get('/users/me/settings');
    return data;
};

/**
 * 
 * @param username 
 */
 const updateSettings = async (settings: UserSettings): Promise<UserSettings> => {
        const { data } = await axios.put("/users/me/settings", settings);
        return data;
};


const service = {
    getUserSettings,
    updateSettings
};

export default service;