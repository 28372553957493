import { Box, Button, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Paths from "@app/routes";

import { Content } from "@components/Content";
import { Header } from "@components/Header";
import { useCallback, useEffect, useState } from "react";
import { GenericPostType, GenericThumbnailPost } from "@features/post/components/GenericThumbnailPost";
import { postService } from '@features/post/services';
import { PostExternalIdentifierType } from "@features/post/models";
import { computeThumbnailPostSize, defaultThumbnailPostWidth } from "@features/post/helpers/post.helper";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { commonSelector } from "@features/common/slices";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useStorageAccounts from "@features/user/hooks/useStorageAccounts";
import { StorageAccount } from "@features/user/models";
import { WebExtensionPost } from "../models";
import { instagramPostService } from "@features/post/instagram/services";
import { LoadingButton } from "@mui/lab";
import { postSlice } from '@features/post/slices'

const lastWebExtensionVersion = "1.0.0";

export const ViewInstagramPosts = () => {
    const { t } = useTranslation('view_instagram_posts');
    const { t: sat } = useTranslation('user_external_accounts');

    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const [ extensionActivated, setExtensionActivated ] = useState<boolean>(false);
    const [ wrongExtensionVersion, setWrongExtensionVersion ] = useState<boolean>(false);
    const [ posts, setPosts ] = useState<Array<WebExtensionPost>>([]);

    const { containerSize } = useAppSelector(commonSelector);
    const [postSize, setPostSize] = useState<number>(defaultThumbnailPostWidth);
    const [ selectedPostIds, setSelectedPostIds ] = useState<Array<string>>([]);

    const { storageAccounts, preferredStorage } = useStorageAccounts();
    const [ selectedStorageAccount , setSelectedStorageAccount ] = useState<StorageAccount>();

    const [ importLoading, setImportLoading] = useState<boolean>(false);

    /**
     * 
    */
    useEffect(() => {
        // register all the listeners to communicate with the web extension
        document.addEventListener("sprinkl-status-resp", handleStatusResponse);
        document.addEventListener("sprinkl-posts-fetch-resp", handlePostsFetchResponse);
        document.dispatchEvent(new CustomEvent("sprinkl-status-req"));
        
        return () => {
            document.removeEventListener("sprinkl-status-resp", handleStatusResponse);
            document.removeEventListener("sprinkl-posts-fetch-resp", handlePostsFetchResponse);
        }
    }, []);

    useEffect(() => {
        if(preferredStorage) {
            setSelectedStorageAccount(preferredStorage);
        }
    }, [preferredStorage]);

     /**
     * 
     */
     useEffect(() => {
        setPostSize(computeThumbnailPostSize(containerSize).postSize);
    }, [containerSize]);
    
    const onBack = () => {
        navigate(Paths.Authenticated.posts);
    }

    /**
     * 
     */
    const handleStatusResponse = useCallback((e: Event) => {
        const installedExtensionVersion: string = (e as CustomEvent).detail;
        setExtensionActivated(true);

        if(installedExtensionVersion !== lastWebExtensionVersion) {
            setWrongExtensionVersion(true);
        } else {
            document.dispatchEvent(new CustomEvent("sprinkl-posts-fetch-req"));
        }
    }, []);

    /**
     * 
     */
    const handlePostsFetchResponse = useCallback(async (e: Event) => {
        const posts: Array<WebExtensionPost> = (e as CustomEvent).detail;

        // load the already existing posts coming from instagram
        const externalIdentifiers = await postService.getExternalIdentifiersByType(PostExternalIdentifierType.INSTAGRAM);

        const updatedPosts = posts.map(post => {
            post.checked = false;
            post.existingPost = externalIdentifiers.find(id => id === post.id) !== undefined;
            return post;
        });

        setPosts(updatedPosts);
    }, []);
    
    /**
     * 
     * @param post 
     */
    const selectPost = (post: WebExtensionPost) => {
        // remove it
        if(selectedPostIds.includes(post.id)) {
            selectedPostIds.splice(selectedPostIds.indexOf(post.id), 1); // 2nd parameter means remove one item only
        } 
        // add it
        else {
            selectedPostIds.push(post.id);
        }
        setSelectedPostIds(selectedPostIds);

        // also update the list of posts
        setPosts(
            posts.map(p => {
                if(p.id === post.id && !post.existingPost) {
                    return {
                        ...p,
                        checked: !post.checked
                    };
                } else {
                    return p;
                }
            })
        );
    }

    /**
     * 
     */
    const selectAllPosts = () => {
        setSelectedPostIds(
            posts.filter(p => !p.checked).map(p => p.id)
        );

        setPosts(
            posts.map(p => {
                if(!p.existingPost) {
                    return {
                        ...p,
                        checked: true
                    };
                } else {
                    return p;
                }
            })
        );
    }

    /**
     * 
     */
    const unselectAllPosts = () => {
        setSelectedPostIds([]);

        setPosts(
            posts.map(p => {
                if(!p.existingPost) {
                    return {
                        ...p,
                        checked: false
                    };
                } else {
                    return p;
                }
            })
        );
    }

    /**
     * 
     */
    const selectStorageAccount = (event: SelectChangeEvent) => {
        const storageType = event.target.value as string;
        setSelectedStorageAccount(storageAccounts!.find(s => s.type === storageType));
    }

    /**
     * 
     */
    const importPosts = async () => {
        if(posts.length > 0 && selectedStorageAccount) {
            setImportLoading(true);
            const createdPosts = await instagramPostService.createPost(posts, selectedStorageAccount);

            const idsOfAddedPosts = createdPosts.map(p => p.id);

            setPosts(
                posts.map(p => {
                    if(!p.existingPost && idsOfAddedPosts.includes(p.id)) {
                        return {
                            ...p,
                            existingPost: true
                        };
                    } else {
                        return p;
                    }
                })
            );

            // dispatch all posts to add them to the post page
            for(let postIds of createdPosts) {
                await dispatch(postSlice.addNewCreatedPost(postIds.postId));
            }

            setImportLoading(false);
        }
    }

    return (
        <>
            <Header onBack={onBack}>
                <Typography variant="h2">{t('title')}</Typography>
            </Header>
            <Content>
                {!extensionActivated && <>
                    <Typography sx={{textAlign: 'center', my: 2}}>{t('no_extension')}</Typography>
                </>}

                {extensionActivated && wrongExtensionVersion && <>
                    <Typography sx={{textAlign: 'center', my: 2}}>{t('wrong_extension_version')}</Typography>
                </>}
                
                {extensionActivated && !wrongExtensionVersion && <Box>
                    <Box sx={{display: 'flex', mt: 2, mb: 2, justifyContent: 'space-between', pr: 2}}>
                        {selectedPostIds.length === 0 && <Button color="primary" startIcon={<CheckBoxIcon />} onClick={selectAllPosts}>{t('select_all_button')}</Button>}
                        {selectedPostIds.length !== 0 && <Button color="primary" startIcon={<CheckBoxOutlineBlankIcon />} onClick={unselectAllPosts}>{t('unselect_all_button')}</Button>}
                        <Box sx={{ display:'flex', columnGap: 2, alignItems: 'center'}}>
                            {storageAccounts && storageAccounts.length == 0 && <>
                                <Typography>{t('no_storage_defined')}</Typography>
                                <Link to={Paths.Authenticated.externalAccounts} style={{ textDecoration: 'none' }}>{t('configure_storage')}</Link>
                            </>}
                            {selectedStorageAccount && storageAccounts && storageAccounts.length > 0 &&
                                <>
                                    <Typography>{t('input_storage_label')}</Typography>
                                    <Select value={selectedStorageAccount.type} onChange={selectStorageAccount}>
                                        {storageAccounts.map((a, i) => {
                                            return <MenuItem key={a.type} value={a.type}>
                                                    {sat(`storage_account_${a.type}`)
                                                }</MenuItem>
                                        })}
                                    </Select>
                                    <LoadingButton color="primary" variant="outlined" disabled={selectedPostIds.length === 0} onClick={importPosts} loading={importLoading}>{t('import_button')}</LoadingButton>
                                </>
                            }
                        </Box>
                    </Box>

                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
                        {posts.length > 0 && posts.map(post => {
                            return (<InstagramThumbnailPost post={post} onClick={selectPost} key={post.id} postSize={postSize} />);
                        })}
                    </div>
                </Box>}
            </Content>
        </>
    );
}

interface InstagramThumbnailPostProps {
    post: WebExtensionPost
    postSize: number
    onClick ?: (post: WebExtensionPost) => void
}

const InstagramThumbnailPost = (props: InstagramThumbnailPostProps) => {
    const post = props.post;

    const onClick = () => {
        if(props.onClick) {
            props.onClick(post);
        }
    }

    const genericPost = {
        id: post.id,
        description: post.description,
        type: post.medias.length > 1 ? GenericPostType.ALBUM : (post.medias[0].type === "VIDEO" ? GenericPostType.VIDEO : GenericPostType.DEFAULT),
        thumbnailUri: post.thumbnail.data
    };

    return <GenericThumbnailPost onClick={onClick} size={props.postSize} post={genericPost} checked={post.existingPost || post.checked} selectable={true}/>
}