import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, generatePath } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import { useAppDispatch } from "@app/hooks";
import Paths from "@app/routes";

import { successChannel } from '@channels/globalNotificationChannel'

import { Content } from "@components/Content"
import { Header } from "@components/Header"
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { SelectGalleryPictureButton } from "@components/SelectGalleryPictureButton";

import { CirclePicture, CircleSettingsForm, IFormInputs as ICircleSettingsInputs } from '@features/circle/components/CircleSettingsForm';
import { CircleUsersList } from "@features/circle/components/CircleUsersList";
import { CircleScope, CircleUser, CircleUserRole } from '@features/circle/models';
import { circleSlice } from '@features/circle/slices'

import { Contact } from "@features/contact/models";
import useCameraDevice from "@hooks/useCameraDevice";
import { CaptureCameraDialog } from "@components/CaptureCameraDialog";
import { CropPictureDialog } from "@components/CropPictureDialog";

interface IFormInputs extends ICircleSettingsInputs {
}

export const CreateCircle = () => {
    const { t } = useTranslation('circle_creation');
    const { t: ct } = useTranslation('common');

    const hasCameraDevice = useCameraDevice();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    // final circle contact list
    const [circleUsers, setCircleUsers] = useState<Array<CircleUser>>([]);

    const [circlePicture, setCirclePicture] = useState<CirclePicture | null>(null);

    const updateCirclePicture = (circlePicture: CirclePicture | null) => {
        setCirclePicture(circlePicture);
    }

    /**
     * 
     */
     const validationSchema = Yup.object({
        name: Yup.string().required(ct('input_required')),
        hideUsers: Yup.boolean().defined(),
        allowComments: Yup.boolean().defined(),
        allowReactions: Yup.boolean().defined(),
        scope: Yup.mixed<CircleScope>().oneOf(Object.values(CircleScope)).defined()
    });

    /**
     * 
     */
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: "",
            hideUsers: true,
            allowComments: true,
            allowReactions: true,
            scope: CircleScope.PRIVATE
        }
    });

    /**
     * 
     * @param data 
     */
    const onSubmit = async (data: IFormInputs) => {
        setLoading(true);
        const circle = await dispatch(circleSlice.createCircle(data.name, circleUsers, {
            hideUsers: data.hideUsers,
            allowComments: data.allowComments,
            allowReactions: data.allowReactions,
            scope: data.scope
        }, circlePicture?.file));
        successChannel.emit(t('notification_create_success'));
        navigate(generatePath(Paths.Authenticated.circle, {"circleId": circle.id}));
    }

    /**
     * 
     * @param users 
     */
    const addUsers = (users: Array<Contact>) => {
        const updatedUsers = circleUsers.concat(users.map(u => {
            return {
                id: u.userId,
                username: u.username,
                pictureUniqueId: u.pictureUniqueId,
                role: CircleUserRole.READER
            }
        }));
        updatedUsers.sort((a, b) => a.username < b.username ? -1 : 1);

        setCircleUsers(updatedUsers);
    }

    /**
     * 
     * @param user 
     */
    const removeUser = (user: CircleUser) => {
        setCircleUsers(circleUsers.filter(u => u.id !== user.id));
    }

    /**
     * 
     * @param user 
     */
    const updateUser = (user: CircleUser) => {
        setCircleUsers(circleUsers.filter(u => u.id === user.id).map(u => {return {...u, role: user.role}}));
    };

    return (
       <>
        <Header>
            <Typography variant="h2">{t('page_title')}</Typography>
        </Header>
        <Content>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <CircleSettingsForm control={control} errors={errors} circlePicture={circlePicture} setCirclePicture={updateCirclePicture}/>

                <Box sx={{ p: 2 }}>
                    <CircleUsersList users={circleUsers} addUsers={addUsers} removeUser={removeUser} updateUser={updateUser} title={t('contact_section_title')} maxHeight="300px"/>

                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', mt: 2 }}>
                        <LoadingButton loading={loading} variant="outlined" color="primary" type="submit" >
                            {ct('validate_button')}
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Content>
       </>
    )
}