import { Header } from "@components/Header";
import { Content } from "@components/Content";
import { Box, IconButton, Typography } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactElement, useEffect, useState } from "react";
import { Loading } from "@components/Loading";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useConfirm } from "material-ui-confirm";
import { successChannel } from '@channels/globalNotificationChannel'
import { StorageAccount, StorageAccountStatus, StorageAccountType, UserPrivilege } from "@features/user/models";
import { storageAccountService } from "@features/user/services";
import GoogleDriveIcon from "@components/icons/GoogleDriveIcon";
import SprinklIcon from "@components/icons/SprinklIcon";
import { hasPrivilege } from "@helpers/security.helper";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { userSelector, userSlice } from "@features/user/slices";
import { StorageAccountHandler, googleDriveHandler, internalHandler } from "@features/user/models/storageAccountHandlers";
import Paths from "@app/routes";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';


export const UserExternalAccounts = () => {
    const { t } = useTranslation('user_external_accounts')


    return (
        <>
            <Header>
                <Typography variant="h2">{t('title')}</Typography>
            </Header>
            <Content>
                <StorageAccounts />
                { /*<InstagramAccounts /> */ }
            </Content>
        </>
    );
}

interface UserStorageAccount {
    type: StorageAccountType;
    icon: ReactElement;
    handler: StorageAccountHandler,
    favorite: boolean,
    data: null | {
        id: string,
        status: StorageAccountStatus
    }
}

const StorageAccounts = () => {
    const { t } = useTranslation('user_external_accounts');
    const confirm = useConfirm();
    const [ storageAccounts, setStorageAccounts ] = useState<Array<UserStorageAccount> | null>(null);
    const { user, settings } = useAppSelector(userSelector);
    const [searchParams, setSearchParams] = useSearchParams();
    const { storageType } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    useEffect(() => {
        (async () => {
            // first handle authorization finalization before loading the accounts
            if(storageType) {
                if(storageType === "googledrive") {
                    const code = searchParams.get('code');
                    if(code) {
                        await googleDriveHandler.finalize(code);
                    }
                }
                setSearchParams({});
                navigate(Paths.Authenticated.externalAccounts);
            }
            
            // then load all the storage accounts
            const accounts = await storageAccountService.loadStorageAccounts();
            const storageAccounts = new Array<UserStorageAccount>();

            const accountBuildingBlocks = [{
                type: StorageAccountType.GOOGLE_DRIVE,
                privilege: UserPrivilege.STORAGE_ACCOUNT,
                icon: <GoogleDriveIcon />,
                handler: googleDriveHandler
            }, {
                type: StorageAccountType.INTERNAL,
                privilege: UserPrivilege.INTERNAL_STORAGE_ACCOUNT,
                icon: <SprinklIcon style={{width:'1.5rem'}}/>,
                handler: internalHandler
            }];

            accountBuildingBlocks.forEach(b => {
                const account = accounts.find(a => a.type === b.type);
                if(hasPrivilege(user, b.privilege) || account) {
                    storageAccounts.push({
                        type: b.type, 
                        icon: b.icon,
                        handler: b.handler,
                        favorite: account && settings!.preferredStorageType === account.type ? true : false,
                        data: account ? {
                            id: account.id,
                            status: account.status
                        } : null
                    });
                }
            });

            setStorageAccounts(storageAccounts);
        })();
    }, [settings]);


    const deleteAccount = async (accountToDelete: UserStorageAccount) => {
        try {
            await confirm({description: t('confirm_delete_storage_account', {name: t('storage_account_' + accountToDelete.type)})});
            await accountToDelete.handler.delete();
            const updatedStorageAccounts = storageAccounts!.map(a => {
                if(a.type === accountToDelete.type) {
                    return {
                        ...a,
                        data: null
                    }
                } else {
                    return a;
                }
            });
            setStorageAccounts(updatedStorageAccounts);
            successChannel.emit(t('notification_delete_account_success'));
        } catch(err){}

    };

    /**
     * 
     * @param account 
     */
    const addAccount = async (account: UserStorageAccount) => {
        const createdStorageAccount = await account.handler.initialize();
        const updatedStorageAccounts = storageAccounts!.map(a => {
            if(a.type === account.type) {
                return {
                    ...a,
                    data: {
                        id: createdStorageAccount.id,
                        status : createdStorageAccount.status
                    }
                }
            } else {
                return a;
            }
        });
        setStorageAccounts(updatedStorageAccounts);
    };

    /**
     * 
     * @param account 
     */
    const toggleFavorite = async (account: UserStorageAccount) => {
        if(account.data && settings) {
            // no previously set favorite storage -> init
            // or different favorite storage
            if(settings.preferredStorageType == null || settings.preferredStorageType !== account.type) {
                await dispatch(userSlice.updateSettings({...settings, preferredStorageType: account.type}));
            }
        }
    };

    if(!hasPrivilege(user, UserPrivilege.STORAGE_ACCOUNT) && !hasPrivilege(user, UserPrivilege.INTERNAL_STORAGE_ACCOUNT)) {
        return <Box sx={{p: 2}}><Typography sx={{textAlign: 'center'}}>{t('no_storage_accounts')}</Typography></Box>;
    }

    return (
        <Box sx={{p: 2}}>
            <Typography variant="h3" sx={{mb: 1}}>{t('storage_accounts_title')}</Typography>
            {storageAccounts == null && <Loading />}
            {storageAccounts && storageAccounts.map((account, i)  => 
                    <Box key={account.type} sx={{display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center', 
                        p: 2, 
                        bgcolor: account.data?.status === StorageAccountStatus.INVALID_CREDENTIALS ? 'error.light' : (i % 2 == 0 ? 'grey.100' : 'white')
                    }}>
                        <Box sx={{display:'flex', columnGap: '1em'}}>
                            {account.icon}
                            <Typography>{t('storage_account_' + account.type)}</Typography>
                            {account.data?.status === StorageAccountStatus.INVALID_CREDENTIALS && <Typography sx={{fontStyle: 'italic'}}>({t('warn_invalid_credentials')})</Typography>}
                        </Box>
                        <Box>
                            {account.data != null && account.favorite && <IconButton aria-label="favorite" onClick={() => toggleFavorite(account)} color="primary">
                                <StarIcon />
                            </IconButton>}
                            {account.data != null && !account.favorite && <IconButton aria-label="favorite" onClick={() => toggleFavorite(account)} color="primary">
                                <StarBorderIcon />
                            </IconButton>}
                            {account.data == null && <IconButton disabled><StarBorderIcon /></IconButton>}

                            {account.data != null && account.data.status === StorageAccountStatus.ACTIVE && <IconButton aria-label="delete" color="error" onClick={() => deleteAccount(account)}>
                                <DeleteIcon />
                            </IconButton>}
                            {(account.data == null || (account.data != null && account.data.status === StorageAccountStatus.INVALID_CREDENTIALS)) && <IconButton aria-label="add" color="error" onClick={() => addAccount(account)}>
                                <AddCircleIcon color="primary" />
                            </IconButton>}
                        </Box>
                    </Box>
                )
            }
        </Box>
    );
}


/* Deprecated
const InstagramAccounts = () => {
    const { t } = useTranslation('user_external_accounts')
    const confirm = useConfirm();
    const [ instagramAccounts, setInstagramAccounts ] = useState<Array<InstagramAccount> | null>(null);

    useEffect(() => {
        (async () => {
            setInstagramAccounts(await instagramService.getAccounts());
        })();
    }, []);

    const deleteAccount = async (account: InstagramAccount) => {
        try {
            await confirm({description: t('confirm_delete_instagram_account', {name: account.label})});
            await instagramService.deleteAccount(account.id);
            setInstagramAccounts(instagramAccounts!.filter(acc => acc.id != account.id));
            successChannel.emit(t('notification_delete_account_success'));
        }
        catch(err){}
    };

    return (
        <Box sx={{p: 2}}>
            <Typography variant="h3" sx={{mb: 1}}>{t('instagram_title')}</Typography>
            {instagramAccounts == null && <Loading />}
            {instagramAccounts != null && instagramAccounts.length == 0 && <Typography sx={{textAlign: 'center'}}>{t('no_instagram_accounts')}</Typography>}
            {instagramAccounts != null && instagramAccounts.length > 0 && 
                instagramAccounts.map((account, i)  => 
                    <Box key={account.id} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, bgcolor: i % 2 == 0 ? 'grey.100' : 'white'}}>
                        <Typography>{account.label}</Typography>
                        <IconButton aria-label="delete" color="error" onClick={() => deleteAccount(account)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                )
            }
        </Box>
    );
}
*/