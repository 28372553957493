import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkAction } from "@app/hooks";
import { User, UserSettings } from "@features/user/models";
import userService from '@features/user/services/userService';
import userSettingsService from '@features/user/services/userSettingsService';
import { clearAuthorizationHeader} from "@app/axios";
import { storeToken, getToken, deleteToken } from '@helpers/token.storage';

export interface UserState {
  user: User | null,
  token: string | null,
  isLoggedIn: boolean,
  settings: UserSettings | null
}

const jsonToken = getToken();

const initialState: UserState = jsonToken
    ? { isLoggedIn: true, token: jsonToken, user: null, settings: null }
    : { isLoggedIn: false, token: null, user: null, settings: null };


export const logoutActionType = "user/logout";

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<User>) => {
        state.isLoggedIn = true;
        state.user = action.payload;
    },
    loginFail: (state) => {
        state.isLoggedIn = false;
        state.user = null;
    },
    logout: (state) => {
        state.isLoggedIn = false;
        state.user = null;
    },
    updateUsername: (state, action: PayloadAction<string>) => {
      state.user!.username = action.payload;
    },
    updateProfilePicture: (state, action: PayloadAction<string>) => {
      state.user!.pictureUniqueId = action.payload;
    },
    deleteProfilePicture: (state) => {
      state.user!.pictureUniqueId = null;
    },
    setSettings: (state, action: PayloadAction<UserSettings>) => {
        state.settings = action.payload;
    }
  },
});

/**
 * 
 * @returns 
 */
const loadCurrentUser = (): AppThunkAction => {
  return async (dispatch) => {
    const user = await userService.getCurrentUser();
    dispatch(userSlice.actions.setCurrentUser(user))
    const settings = await userSettingsService.getUserSettings();
    dispatch(userSlice.actions.setSettings(settings));
  }
};

/**
 * 
 * @returns 
 */
 const logout = (): AppThunkAction => {
    return async (dispatch ) => {
        await userService.logout();
        deleteToken();
        clearAuthorizationHeader();
        dispatch(userSlice.actions.logout());
    };
  };
  
  /**
   * 
   * @param username 
   * @param password 
   * @returns 
   */
  const signin = (username: string, password: string): AppThunkAction => {
    return async (dispatch) => {
      const authenticationData = await userService.authenticate(username, password);
      storeToken(authenticationData.sessionId);
      //setAuthorizationHeader(token);

      const user = await userService.getCurrentUser();
      dispatch(userSlice.actions.setCurrentUser(user));
      const settings = await userSettingsService.getUserSettings();
      dispatch(userSlice.actions.setSettings(settings));
    };
  };
  
  /**
   * 
   * @param username 
   * @param email 
   * @param password 
   * @returns 
   */
  const signup = (username: string, email: string, password: string): AppThunkAction => {
    return async () => {
      await userService.createUser(username, email, password);
    }
  };

  /**
   * 
   * @param username 
   */
  const updateUsername = (username: string): AppThunkAction => {
    return async(dispatch) => {
      await userService.updateUsername(username);
      dispatch(userSlice.actions.updateUsername(username));
    }
  };

/**
 * 
 * @param picture 
 */
const updateProfilePicture = (picture: Blob): AppThunkAction => {
  return async(dispatch) => {
    const pictureUniqueId = await userService.updateProfilePicture(picture);
    dispatch(userSlice.actions.updateProfilePicture(pictureUniqueId));
  };
};

/**
 * 
 */
const deleteProfilePicture = (): AppThunkAction => {
  return async(dispatch) => {
    await userService.deleteProfilePicture();
    dispatch(userSlice.actions.deleteProfilePicture());
  }
};

/**
 * 
 * @param settings 
 */
const updateSettings = (settings: UserSettings): AppThunkAction => {
  return async(dispatch) => {
    const updatedSettings = await userSettingsService.updateSettings(settings);
    dispatch(userSlice.actions.setSettings(settings));
  };
};

export const userSelector = (state: { user: UserState }) => state.user;

export const reducer = userSlice.reducer;

const slice = {
  loadCurrentUser,
  signup,
  signin,
  logout,
  updateUsername,
  updateProfilePicture,
  deleteProfilePicture,
  updateSettings
};

export default slice;