import axios from '@app/axios';

import { InstagramPost, InstagramAccount } from '@features/post/instagram/old/models';

interface Paging {
    beforeCursor: string
    afterCursor: string
}

interface PageData<T> {
    paging: Paging
    posts: Array<T>
}

/**
 * 
 */
const getAuthenticationUrl = async(): Promise<string> => {
    const { data } = await axios.get('/instagram/credentials/url');
    return data;
};

/**
 * 
 * @param code 
 */
const finalizeAuthentication = async(code: string): Promise<InstagramAccount> => {
    const { data } = await axios.post('/instagram/credentials', {
        code: code
    });
    return data;
};

/**
 * 
 */
const getAccounts = async() : Promise<Array<InstagramAccount>> => {
    const { data } = await axios.get('/instagram/accounts');
    return data;
}

/**
 * 
 * @param accountId 
 * @returns 
 */
const deleteAccount = async(accountId: string) => {
    await axios.delete(`/instagram/accounts/${accountId}`);
};

/**
 * Load user's instagram posts
 * @returns 
 */
const getPosts = async (accountId: string, nextPageCursor: string | null): Promise<PageData<InstagramPost>> => {
    const query = nextPageCursor ? `?after_cursor=${nextPageCursor}` : '';
    const { data } = await axios.get(`/instagram//accounts/${accountId}/posts${query}`);
    return data;
};

/**
 * 
 * @param instagramPostId 
 */
const createPost = async (accountId: string, instagramPostId: string) => {
    const { data } = await axios.post(`/instagram/accounts/${accountId}/posts/${instagramPostId}`);
    return data;
};

const service = {
    getAuthenticationUrl,
    finalizeAuthentication,
    getAccounts,
    getPosts,
    createPost,
    deleteAccount
};

export default service;