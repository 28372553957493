import { GenericPostType, GenericThumbnailPost } from '@features/post/components/GenericThumbnailPost';

import { InstagramPost, MediaType } from "@features/post/instagram/old/models";

interface InstagramThumbnailPostProps {
    post: InstagramPost
    size: number
    onClick ?: (post: InstagramPost) => void
}

export const InstagramThumbnailPost = (props: InstagramThumbnailPostProps) => {
    const post = props.post;

    const onClick = () => {
        if(props.onClick) {
            props.onClick(post);
        }
    }

    const genericPost = {
        id: post.id,
        description: post.caption,
        type: post.mediaType === MediaType.CAROUSEL_ALBUM ? GenericPostType.ALBUM : (post.mediaType === MediaType.VIDEO ? GenericPostType.VIDEO : GenericPostType.DEFAULT),
        thumbnailUri: post.thumbnailUrl
    };

    return <GenericThumbnailPost onClick={onClick} size={props.size} post={genericPost} checked={post.added} selectable={post.added}/>
}