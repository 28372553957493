import { Avatar, Box, IconButton, ListItemIcon, Menu, MenuItem, Skeleton, Typography } from '@mui/material';
import { generatePath, Outlet, useLocation, useNavigate, useParams,  matchPath as matchPathBase } from 'react-router-dom';
import React, {useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Tune';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CollectionsIcon from '@mui/icons-material/Collections';
import { useConfirm } from 'material-ui-confirm';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import Paths from '@app/routes';

import { Content } from '@components/Content';
import { Header } from '@components/Header';
import { Loading } from '@components/Loading';

import { EmptyCircle } from '@features/circle/components/EmptyCircle';
import { CircleTab, CircleTabs, CircleTabType } from '@features/circle/models/circleNavigation';
import { CircleAuthorization } from '@features/circle/models';
import { circleSlice, circleSelector, circleUserSelector } from '@features/circle/slices';
import { commonSlice } from '@features/common/slices';
import { CircleAvatar } from './CircleAvatar';



const matchPath = (patterns: Array<string>, pathname: string) => [patterns].flat().find((pattern) => matchPathBase(pattern, pathname));

export const CircleLayout = () => {
    const { t } = useTranslation('circle');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const confirm = useConfirm();
    
    // delect which circle route has been selected
    const { pathname } = useLocation();
    const pattern = matchPath(CircleTabs.ALL.map(t => t.route), pathname);
    
    const { circle, loadingCircle, selectedTab } = useAppSelector(circleSelector);
    const { users } = useAppSelector(circleUserSelector);
    const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);
    const { circleId } = useParams();


    /**
     * Initial load - executed when selecting a circle
     * Load posts & set focus on last read posts if defined
     */
     useEffect(() => {
        (async () => {
            if(circleId) {
                if (circle == null || circle.id !== circleId) {
                    dispatch(circleSlice.selectCircle(circleId));
                }
            }
        })();
    }, [circleId]);


    /**
     * The current url match a circle tab => select it
     */
    useEffect(() => {
        if(pattern) {
            const selectedTab = CircleTabs.findByRoute(pattern);
            if(selectedTab) {
                dispatch(circleSlice.selectTab(selectedTab));
            }
        }
    }, [pattern])

    /**
     * 
     * @param tab 
     */
    const onDisplayTab = (tab: CircleTab) => {
        navigate(generatePath(tab.route, {'circleId': circle!.id}));
    }

    /**
     * If user visualize the posts page => open de drawer
     * else go back on the post page
     */
    const onBack = () => {
        const tab = CircleTabs.findByType(selectedTab);
        if(tab) {
            if(tab.backTab) {
                navigate(generatePath(tab.backTab.route, {'circleId': circle!.id}));
            } else {
                dispatch(commonSlice.openDrawer());
            }
        }
    }

    /**
     * 
     */
    const navigateToDetails = () => {
        navigate(generatePath(Paths.Authenticated.circleDetails, {'circleId': circle!.id}));
    };

    /**
     * 
     */
    const leaveCircle = async () => {
        if(circle) {
            try {
                await confirm({description: t('confirm_leave_content', {name: circle.name})})
                await dispatch(circleSlice.leaveCircle(circle.id));
                navigate(Paths.Authenticated.home);
            } catch(err) {}
        }
    };

    if(circle == null) {
        if(loadingCircle) {
            return (
                <>
                    <SkeletonHeader />
                    <Loading />
                </>
            );
        }
        return (
            <>
                <Header></Header>
                <EmptyCircle />
            </>
            
        );
    }

    return (
        <>
            <Header onBack={selectedTab === CircleTabType.POSTS ? undefined :  onBack}>
                <Box sx={{display: 'flex', alignItems: 'center', flexGrow: 1, overflow:'hidden'}}>
                    <CircleAvatar circle={circle}  sx={{mr: 1}}/>
                    <Box sx={{ display: 'flex', flexDirection: 'column', overflow:'hidden', cursor: 'pointer'}} onClick={navigateToDetails}>
                        <Typography variant="h2" noWrap>
                            {circle.name}
                        </Typography>
                        {circle.authorizations.includes(CircleAuthorization.SHOW_TITLE_USERS) && 
                            <Typography variant="subtitle1" noWrap sx={{overflow:'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                {users?.map(u => u.username).join(', ')}
                            </Typography>
                        }
                    </Box>
                </Box>

                <IconButton sx={{flexShrink: 0}} onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorElMenu(event.currentTarget)}>
                    <MenuIcon />
                </IconButton>

                <Menu
                    anchorEl={anchorElMenu}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElMenu)}
                    onClose={() => setAnchorElMenu(null)}
                    onClick={() => setAnchorElMenu(null)}
                    transitionDuration={{exit: 0}}>
                    {[
                        {tab: CircleTabs.POSTS, name: 'menu_posts', icon: <CollectionsIcon fontSize="small" />},
                        {tab: CircleTabs.ADD_POST, name: 'menu_add_post', authorization: CircleAuthorization.ADD_POST, icon: <AddPhotoAlternateIcon fontSize="small" />},
                        {tab: CircleTabs.SETTINGS, name: 'menu_settings', authorization: CircleAuthorization.EDIT_CIRCLE, icon: <SettingsIcon fontSize="small" />},
                        {tab: CircleTabs.MANAGE_CONTACTS, name: 'menu_manage_contacts', authorization: CircleAuthorization.MANAGE_USERS, icon: <ManageAccountsIcon fontSize="small" />},
                    ]
                    .filter(menu => !menu.authorization || circle.authorizations.includes(menu.authorization))
                    .map((item) => (
                        <MenuItem onClick={() => onDisplayTab(item.tab)} sx={{display: selectedTab === item.tab.type ? 'none' : 'block'}} key={item.tab.type}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            {t(item.name)}
                        </MenuItem>
                    ))}

                    {circle.authorizations.includes(CircleAuthorization.LEAVE_CIRCLE) && 
                        <MenuItem onClick={leaveCircle}>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            {t('menu_leave')}
                        </MenuItem>
                    }

                </Menu>
            </Header>

            <Content>
                <Outlet />
            </Content>
        </>

    );
};

const SkeletonHeader = () => {
    return (
        <Header>
            <Box sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
                <Skeleton variant="circular" width={40} height={40} sx={{mr: 1}}/>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%'}}>
                    <Skeleton variant="rectangular" height={25} sx={{mb: 1}}/>
                    <Skeleton variant="rectangular" height={25} />
                </Box>
            </Box>
        </Header>
    );
}