import { Avatar, Badge, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { SyntheticEvent } from "react";
import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector} from '@app/hooks';
import Paths from '@app/routes';

import { Loading } from "@components/Loading";

import { circleSelector } from '@features/circle/slices'

import { commonSlice } from '@features/common/slices';
import { CircleAvatar } from "./CircleAvatar";

export const ListCircles = () => {
    const { t } = useTranslation('circle');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { circles } = useAppSelector(circleSelector);

    const onSelectCircle = (ev: SyntheticEvent, circleId: string) => {
        ev.preventDefault();
        dispatch(commonSlice.closeDrawer());
        navigate(generatePath(Paths.Authenticated.circle, {"circleId": circleId}));
    };

    if(circles == null) {
        return (
            <Loading />
        );
    } else if (circles.length === 0) {
        return (
            <Typography sx={{textAlign: 'center', p: 2, mt: 2}}>{t('list_no_circles')}</Typography>
        );
    } else {
        return (
            <List>
                {circles.map((c, index) => (
                    <ListItem key={c.id} disablePadding>
                        <ListItemButton onClick={event => onSelectCircle(event, c.id)}>
                            <ListItemIcon>
                                <Badge badgeContent={c.unread} max={99} color="primary">
                                    <CircleAvatar circle={c} />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary={c.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        );
    }
}