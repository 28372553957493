
import { useTranslation } from "react-i18next"

export default function useTranslate(namespace: string) {
    const { t } = useTranslation(namespace);

    return {
        t: (key: string, datas ?: Map<string, string>) => {
            if(datas && datas instanceof Map) {
                return t(key, Object.fromEntries(datas));
            } else {
                return t(key);
            }
        }
    };
}